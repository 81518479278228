import { Box } from "@mui/material";
import React from "react";
import { Delete, Edit } from "@mui/icons-material";
import { formatDateAndTime } from "src/utils/formatTime";
import classNames from "classnames";
import NotesModal from "./notes-modal";
import { CommonButton } from "src/components/common/common-button";
import NameProfilePic from "src/components/common/name-profile-pic";

const Notes = ({
  styles,
  data,
  handleDeleteNotes,
  notesData,
  setNotesData,
  handleSubmit,
  notesFormOpen,
  setNotesFormOpen,
}) => {
  const renderMessages = () => {
    return data?.lead_notes.map((message, index) => (
      <Box
        key={index}
        className={`${styles["avtar_box"]} ${styles["note_card"]}`}
      >
        <Box>
          <Box className={styles["uname_date"]}>
            {/* {message?.created_by_detail?.profile_pic !== null ? (
              <img
                src={data?.profile_pic}
                alt="user"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
            ) : ( */}
            <NameProfilePic
              letter={
                message?.created_by_detail?.fullname
                  ? message?.created_by_detail?.fullname[0].toUpperCase()
                  : ""
              }
              customStyle={{
                height: "48px !important",
                width: "48px !important",
              }}
            />
            {/* )} */}
            <Box className={styles["name_text"]}>
              <h2 className={styles["notes_title"]}>{message?.title}</h2>
              <p className={styles["notes_title"]}>
                {message?.created_by_detail?.fullname} -{" "}
                {formatDateAndTime(message?.created_at)}
              </p>
            </Box>
          </Box>
          <Box className={styles["comment_text"]}>{message?.description}</Box>
          <Box className={styles["notes_chip_main"]}>
            <Box
              className={styles["notes_chip_edit"]}
              onClick={() => {
                setNotesFormOpen(true);
                setNotesData({
                  ...notesData,
                  editing: true,
                  message: message,
                  notes_id: message?.id,
                });
              }}
            >
              <Edit className={styles["note_action_icon"]} /> Edit
            </Box>
            <Box
              className={styles["notes_chip_delete"]}
              onClick={(e) => {
                handleDeleteNotes(e, message?.id);
              }}
            >
              <Delete className={styles["note_action_icon"]} /> Delete
            </Box>
          </Box>
        </Box>
      </Box>
    ));
  };

  return (
    <Box
      className={classNames(
        "common-box-shadow",
        styles["main_card"],
        styles["notes_main"]
      )}
    >
      <Box className={styles["message-div"]}>
        {data?.lead_notes.length !== 0 ? (
          renderMessages()
        ) : (
          <Box className={styles["empty-notes"]}>
            Oops..! No any Notes yet..!
          </Box>
        )}
      </Box>
      <Box className={styles["create-btn"]}>
        <CommonButton
          text={"Create Note"}
          handleClick={() => setNotesFormOpen(true)}
        />
      </Box>
      <NotesModal
        styles={styles}
        open={notesFormOpen}
        handleChange={(e) => {
          setNotesData({
            ...notesData,
            message: { ...notesData?.message, [e.target.name]: e.target.value },
          });
        }}
        handleSubmit={handleSubmit}
        handleClose={() => setNotesFormOpen(false)}
        notesData={notesData}
      />
    </Box>
  );
};

export default Notes;
