import { Box } from "@mui/material";
import React from "react";
import KeyDetails from "./key-details";
import MoreInfo from "./more-info";


const Summary = ({
  styles,
  data,
  isSummaryEditing,
  editedData,
  handleChange,
}) => {
  return (
    <Box className={styles["summary-main"]}>
      <Box className={styles["first-col"]}>
        <KeyDetails styles={styles} data={data} />
        <Box className={styles["vertical-spacer"]} />
      </Box>
      <Box className={styles["second-col"]}></Box>
      <Box className={styles["third-col"]}>
        <MoreInfo styles={styles} data={data} />
        {/* <Box className={styles["skills_box"]}>
          <Box
            className={classNames("common-box-shadow", styles["info_box_one"])}
          >
            <Box className={styles["summary-header"]}>Information</Box>
            <Box className={styles["summary-header-light"]}>Lead Owner</Box>
            <Box className={styles["assigned-info"]}>
              <NameProfilePic
                letter={
                  data?.lead_detail?.lead_owner?.full_name
                    ? data?.lead_detail?.lead_owner?.full_name[0].toUpperCase()
                    : ""
                }
                customClass={styles["profile-icon"]}
                customStyle={{
                  fontSize: "13px !important",
                }}
              />
              <Box className={styles["owner_detail"]}>
                <Box className={styles["owner_name"]}>
                  {data?.lead_detail?.lead_owner?.fullname || "---"}
                </Box>
                <Box className={styles["owner_date"]}>
                  {data?.lead_detail?.created_at
                    ? formatDateAndTime(data?.lead_detail?.created_at)
                    : "---"}
                </Box>
              </Box>
            </Box>
            <hr style={{ margin: "20px 0 15px 0" }} />
            <Box className={styles["summary-header-light"]}>
              General Information
            </Box>
            <Box className={styles["gen-info"]}>
              <Box className={styles["created_date_box"]}>
                <Box className={styles["created_date_title"]}>
                  Created Date:{" "}
                </Box>
                <Box className={styles["created_date"]}>
                  {data?.created_at ? formatDate(data?.created_at) : "----"}
                </Box>
              </Box>
              <Box className={styles["created_date_box"]}>
                <Box className={styles["created_date_title"]}>Created by: </Box>
                <Box className={styles["created_date"]}>
                  {data?.created_by?.fullname || "----"}
                </Box>
              </Box>
              <Box className={styles["status_box"]}>
                <Box className={styles["created_date_title"]}>Status: </Box>
                {isSummaryEditing ? (
                  <CommonDropdownField
                    name="status"
                    defaultValue={"default"}
                    value={editedData?.status || " "}
                    handleChange={(e) => handleChange("status", e.target.value)}
                    placeholder={"Status"}
                    options={[
                      { label: "Assigned", value: "assigned" },
                      { label: "Not Assigned", value: " " },
                      { label: "Converted", value: "converted" },
                    ]}
                    customClass={styles["status_dropdown"]}
                  />
                ) : (
                  <Box className={data?.lead_detail?.status && "info-chip"}>
                    {data?.lead_detail?.status || "----"}
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        </Box> */}
      </Box>
    </Box>
  );
};

export default Summary;
