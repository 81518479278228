import SvgColor from "../../../components/svg-color";

const icon = (name) => (
  <SvgColor
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);

const leadSubMenu = [
  {
    title: "Summary",
    path: "lead-summary",
    icon: icon("ic_summary"),
  },
  {
    title: "Notes",
    path: "lead-notes",
    icon: icon("ic_notes"),
  },
  {
    title: "Attachment",
    path: "lead-attachment",
    icon: icon("ic_attachment"),
  },
  {
    title: "Activity",
    path: "lead-activity",
    icon: icon("ic_lead_task"),
  },
];

const dealSubMenu = [
  {
    title: "Summary",
    path: "deals-summary",
    icon: icon("ic_summary"),
  },
];

const navConfig = [
  {
    title: "dashboard",
    name: "dashboard",
    path: "/dashboard/app",
    icon: icon("ic_analytics"),
  },
  {
    title: "Leads",
    name: "lead",
    path: "/dashboard/leads",
    icon: icon("ic_leads"),
    children: leadSubMenu,
  },
  {
    title: "Deals",
    name: "deals",
    path: "/dashboard/deals",
    icon: icon("ic_deals"),
    children: dealSubMenu,
  },
  {
    title: "accounts",
    name: "account",
    path: "/dashboard/accounts",
    icon: icon("ic_accounts"),
  },
  {
    title: "contacts",
    name: "contact",
    path: "/dashboard/contacts",
    icon: icon("ic_contacts"),
  },
  {
    title: "help-center",
    name: "Help Center",
    path: "/dashboard/help-center",
    icon: icon("call-center"),
  },
];

export default navConfig;
