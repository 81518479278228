import React from "react";
import { Helmet } from "react-helmet-async";
import { styled } from "@mui/material/styles";
import { Container, Typography, Grid } from "@mui/material";
import { ToastContainer } from "react-toastify";
import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import Logo from "../../../components/logo";
import { useLoginMutation } from "../../../hooks/useSessiondata";
import { showNotification } from "../../../components/notification";
import "react-toastify/dist/ReactToastify.css";
import { AuthForm } from "src/sections/auth/login";

const StyledRoot = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const StyledContent = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  height: "100vh",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(12, 0),
}));

const LoginPage = () => {
  const loginMutation = useLoginMutation();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const handleLogin = async (email, password) => {
    try {
      await loginMutation.mutateAsync(
        { email, password },
        {
          onSuccess: (data) => {
            queryClient.setQueryData("token", data);
            localStorage.setItem("token", JSON.stringify(data));
            showNotification("success", "Welcome", 2000);
            navigate("/dashboard");
          },
        }
      );
    } catch (error) {
      showNotification("error", error, 8000);
    }
  };

  return (
    <>
      <ToastContainer />
      {loginMutation.isLoading}
      <Helmet>
        <title> Sign in | Lead Management System </title>
      </Helmet>

      <StyledRoot>
        <Container maxWidth="sm">
          <StyledContent>
            <Grid
              sx={{
                display: "flex",
                justifyContent: "center",
                paddingBottom: "20px",
              }}
            >
              <Logo />
            </Grid>
            <Grid sx={{ textAlign: "center", marginBottom: "50px" }}>
              <Typography variant="h4" gutterBottom>
                Sign in to Lead Management System
              </Typography>
            </Grid>

            <AuthForm type="login" handleUser={handleLogin} />
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
};

export default LoginPage;
