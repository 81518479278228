import React from "react";
import { Box, Grid } from "@mui/material";
import ProposalCard from "./proposal-card";

const ProposalPhase = ({
  styles,
  proposalsData,
  setProposalFormData,
  setEditing,
  handleModalOpen,
}) => {
  return (
    <Box className={styles["analysis_main"]}>
      {proposalsData?.length === 0 && (
        <Box className={styles["analysis_box_main"]}>
          <Box>
            Please Send proposal By Clicking On Send Proposal On Dropdown Menu
          </Box>
        </Box>
      )}
      <Box className={styles["analysis_main"]}>
        <Grid container spacing={2}>
          {proposalsData?.map((proposalData, index) => (
            <Grid item xs={12} md={6}>
              <ProposalCard
                styles={styles}
                data={proposalData}
                index={index}
                handleClick={() => {
                  handleModalOpen("sendProposalModal");
                  setProposalFormData({ ...proposalData });
                  setEditing(true);
                }}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default ProposalPhase;
