import * as React from "react";

import Home from "./components/home";
import Features from "./components/feature-section";
import Pricing from "./components/pricing";
import Testimonial from "./components/testimonials";
import Clients from "./components/client";
import FAQ from "./components/faq";
import Footer from "./components/footer";
import About from "./components/about";
import ContactForm from "./components/contact-form";
import styles from "./style.module.css";
import { Box } from "@mui/material";

function LandingPage() {  
  return (
    <>
    <Box className={styles["home-container"]}>
      <Home />
      <Features />
      <About/>
      {/* <Pricing /> */}
      <Clients/>
      <Testimonial/>
      
      <ContactForm/>
      <FAQ />
     
      <Footer />
      </Box>
    </>
  );
}
export default LandingPage;
