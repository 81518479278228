import { Box, MenuItem, Popover, Typography } from "@mui/material";
import classNames from "classnames";
import React from "react";
import NameProfilePic from "src/components/common/name-profile-pic";
import {
  Edit,
  EmailOutlined,
  LeaderboardOutlined,
  PhoneAndroidOutlined,
  Save,
  Language,
  PublishedWithChanges,
} from "@mui/icons-material";


const Header = ({
  styles,
  activeTab,
  setActiveTab,
  data,
  setIsSummaryEditing,
  isSummaryEditing,
  editedData,
  handleEdit,
  handleSave,
  handleConvertOpen,
  anchorEl,
  handleOpenMenu,
  handleCloseMenu,
  open,
}) => {
  return (
    <Box className={classNames("common-box-shadow", styles["header-main-div"])}>
      <Box className={styles["account-details"]}>
        <Box className={styles["profile-info"]}>
          <NameProfilePic
            letter={
              data?.lead_detail?.first_name
                ? data?.lead_detail?.first_name[0].toUpperCase()
                : ""
            }
            customClass={styles["profile-icon"]}
            customStyle={{
              fontSize: "13px !important",
            }}
          />
          <Box>
            <Typography className={styles["account-name"]}>
              {data?.lead_detail?.first_name} {data?.lead_detail?.last_name}
              <Box className={styles["head_stacks"]}>
                <Box
                  className={classNames(
                    "info-chip",
                    styles["custom-chip"],
                    data?.lead_detail?.is_active ? "" : "danger-chip"
                  )}
                >
                  {data?.lead_detail?.is_active ? "Active" : "Inactive"}
                </Box>
              </Box>
            </Typography>
            <Box className={styles["short-info"]}>
              <Box className="in-box">
                <Box className={styles["head_stack"]}>
                  <Box className={styles["head_stacks2"]}>
                    <LeaderboardOutlined className={styles["org_icon"]} />
                    <input
                      value={
                        isSummaryEditing
                          ? editedData?.org?.name
                          : data?.lead_detail?.org?.name || "----"
                      }
                      onChange={(e) =>
                        handleEdit("org", {
                          ...editedData?.org,
                          name: e.target.value,
                        })
                      }
                      readOnly
                      className={styles["editable_field"]}
                    />
                  </Box>
                  <Box className={styles["head_stacks1"]}>
                    <Language className={styles["org_icon"]} />
                    <input
                      value={
                        isSummaryEditing
                          ? editedData?.website
                          : data?.lead_detail?.website || "----"
                      }
                      onChange={(e) =>
                        handleEdit("website", {
                          ...editedData?.org,
                          name: e.target.value,
                        })
                      }
                      readOnly
                      className={styles["editable_field"]}
                    />
                  </Box>
                </Box>
                <Box className={styles["head_stack"]}>
                  <Box
                    className={classNames(
                      styles["head_stacks2"],
                      isSummaryEditing ? styles["editable_field_border"] : ""
                    )}
                  >
                    <EmailOutlined className={styles["org_icon"]} />
                    <input
                      value={
                        isSummaryEditing
                          ? editedData?.email
                          : data?.lead_detail?.email || "----"
                      }
                      name="email"
                      onChange={(e) => handleEdit("email", e.target.value)}
                      className={classNames(styles["editable_field"])}
                    />
                  </Box>
                  <Box
                    className={classNames(
                      styles["head_stacks2"],
                      isSummaryEditing ? styles["editable_field_border"] : ""
                    )}
                  >
                    <PhoneAndroidOutlined className={styles["org_icon"]} />
                    <input
                      value={
                        isSummaryEditing
                          ? editedData?.phone
                          : data?.lead_detail?.phone || "----"
                      }
                      // readOnly
                      name="phone"
                      onChange={(e) => handleEdit("phone", e.target.value)}
                      className={styles["editable_field"]}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className={styles["owner-info"]}>


          {data?.lead_detail?.lead_owner && (
            <Box className={styles["account-owner-profile"]}>
              <NameProfilePic
                //   letter={
                //     data?.lead_owner?.first_name
                //       ? data?.lead_owner?.first_name[0].toUpperCase()
                //       : ""
                //   }
                letter={"S"}
                customClass={styles["profile-icon"]}
                customStyle={{
                  fontSize: "11px !important",
                }}
              />
              <Box className={styles["annual-revenue"]}>
                <Typography className={styles["data"]}>
                  Sagar Sharma
                </Typography>
                <Typography className={styles["header"]}>Owner</Typography>
              </Box>
            </Box>
          )}

        </Box>
      </Box>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={() => handleCloseMenu("attachment")}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {isSummaryEditing ? (
          <MenuItem
            onClick={() => {
              handleCloseMenu("attachment");
              handleSave();
              setIsSummaryEditing(false);
            }}
            className={styles["attach_popup"]}
          >
            <Save className={styles["attach_popup_icon"]} />
            Save
          </MenuItem>
        ) : (
          <MenuItem
            onClick={() => {
              handleCloseMenu("attachment");
              setIsSummaryEditing(true);
            }}
            className={styles["attach_popup"]}
          >
            <Edit className={styles["attach_popup_icon"]} />
            Edit
          </MenuItem>
        )}
        <MenuItem
          onClick={handleConvertOpen}
          // onClick={(e) => {
          //   handleCloseMenu("attachment");
          // }}
          className={styles["attach_popup"]}
        >
          <PublishedWithChanges className={styles["attach_popup_icon"]} />
          Convert
        </MenuItem>
      </Popover>
    </Box>
  );
};

export default Header;
