import ReactApexChart from "react-apexcharts";
import { useTheme } from "@mui/material/styles";
import { Card, CardHeader, Typography } from "@mui/material";
import { fNumber } from "../../../utils/formatNumber";
import { useChart } from "../../../components/chart";

const TaskBySite = ({ title, subheader, chartColors, chartData }) => {
  const siteColors = [ "#ABD4F7", "#1F68A5", "#123B5E"];
  const theme = useTheme();
  const chartLabels = chartData.map((i) => i.label);
  const chartSeries = chartData.map((i) => i.value);

  const chartOptions = useChart({
    colors: chartData.map(
      (item, index) => siteColors[index % siteColors.length]
    ),
    labels: chartLabels,
    stroke: { colors: [theme.palette.background.paper], width: 0.5 },
    legend: {
      show: true,
      position: "bottom",
      horizontalAlign: "center",
      onItemClick: {
        toggleDataSeries: true,
      },
    },
    tooltip: {
      fillSeriesColor: false,
      y: {
        formatter: (seriesName) => fNumber(seriesName),
        title: {
          formatter: (seriesName) => `${seriesName}: `,
        },
      },
    },
    plotOptions: {
      pie: {
        dataLabels: { offsetY: 30, textAnchor: "middle" },
        donut: { labels: { show: false } },
      },
    },
  });

  const styles = {
    card: { height: "99%" },
    title: { fontSize: "14px", textAlign: "center", fontWeight: "bold" },
    chart: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: "20px",
    },
  };

  return (
    <Card sx={styles?.card}>
      <CardHeader
        title={
          <Typography
            sx={{ fontSize: "14px", textAlign: "center", fontWeight: "bold" }}
          >
            {title}
          </Typography>
        }
        sx={{paddingTop: "15px"}}
        subheader={subheader}
      />
      <ReactApexChart
        type="pie"
        series={chartSeries}
        options={chartOptions}
        height={240}
        width={250}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          // padding: "20px",
        }}
      />
    </Card>
  );
};

export default TaskBySite;
