import PropTypes from "prop-types";
import React from "react";
import {
  Box,
  Stack,
  Button,
  Drawer,
  Divider,
  Typography,
  Select,
  MenuItem,
  IconButton,
} from "@mui/material";
import Iconify from "src/components/iconify";
import Scrollbar from "src/components/scrollbar";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

export const MONTHS_OPTIONS = [
  { value: "jan", label: "January" },
  { value: "feb", label: "February" },
  { value: "mar", label: "March" },
  { value: "apr", label: "April" },
  { value: "may", label: "May" },
  { value: "jun", label: "June" },
  { value: "jul", label: "July" },
  { value: "aug", label: "August" },
  { value: "sep", label: "September" },
  { value: "oct", label: "October" },
  { value: "nov", label: "November" },
  { value: "dec", label: "December" },
];
export const APPLICATION_NAME = ["All", "App1", "App2", "App3"];
export const PROCESS_NAME = ["Name", "Process1", "Process2", "Process3"];
export const RUN_ID = ["1", "2", "3", "4"];

export default function Filters({
  openFilter,
  onOpenFilter,
  onCloseFilter,
  value,
  setValue,
  selectedMonth,
  setSelectedMonth,
}) {
  const renderDate = (
    <Box className="filter-date">
      <Stack spacing={1}>
        <Typography variant="subtitle2">Date</Typography>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={["DatePicker", "DatePicker"]}>
            <DatePicker
              views={["year", "month"]}
              value={value}
              onChange={(newValue) => setValue(newValue)}
            />
          </DemoContainer>
        </LocalizationProvider>
      </Stack>
    </Box>
  );

  const defaultValue = {
    status: "All",
    application: "App1",
    process: "Name",
    runId: "1",
  };

  const renderMonthsList = (
    <Stack spacing={1}>
      <Typography variant="subtitle2">Months</Typography>
      <Select
        defaultValue={selectedMonth}
        variant="outlined"
        style={{ width: "100%" }}
      >
        {MONTHS_OPTIONS.map((item, index) => (
          <MenuItem
            key={index}
            value={item?.value?.toLowerCase()}
            onClick={(e) => setSelectedMonth(item?.value?.toLowerCase())}
          >
            {item?.label}
          </MenuItem>
        ))}
      </Select>
    </Stack>
  );
  const renderApplicationName = (
    <Stack spacing={1}>
      <Typography variant="subtitle2">Application Name</Typography>
      <Select
        defaultValue={defaultValue.application}
        variant="outlined"
        style={{ width: "100%" }}
      >
        {APPLICATION_NAME.map((item) => (
          <MenuItem key={item} value={item}>
            {item}
          </MenuItem>
        ))}
      </Select>
    </Stack>
  );
  const renderProcessName = (
    <Stack spacing={1}>
      <Typography variant="subtitle2">Process Name</Typography>
      <Select
        defaultValue={defaultValue.process}
        variant="outlined"
        style={{ width: "100%" }}
      >
        {PROCESS_NAME.map((item) => (
          <MenuItem key={item} value={item}>
            {item}
          </MenuItem>
        ))}
      </Select>
    </Stack>
  );
  const renderRunId = (
    <Stack spacing={1}>
      <Typography variant="subtitle2">Run ID</Typography>
      <Select
        defaultValue={defaultValue.runId}
        variant="outlined"
        style={{ width: "100%" }}
      >
        {RUN_ID.map((item) => (
          <MenuItem key={item} value={item}>
            {item}
          </MenuItem>
        ))}
      </Select>
    </Stack>
  );

  return (
    <>
      <Button
        disableRipple
        color="inherit"
        endIcon={<Iconify icon="ic:round-filter-list" />}
        onClick={onOpenFilter}
        sx={{
          px: 2,
          py: 1,
          borderRadius: 1,
          backgroundColor: "#174276",
          color: "#ffff",
          marginRight: "10px",
          "&:hover": { backgroundColor: "#fff", color: "#174276" },
        }}
      >
        Filters&nbsp;
      </Button>

      <Drawer
        anchor="right"
        open={openFilter}
        onClose={onCloseFilter}
        PaperProps={{
          sx: {
            width: 280,
            border: "none",
            overflow: "hidden",
          },
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ px: 1, py: 2 }}
        >
          <Box className="" sx={{ display: "flex" }}>
            <img
              alt="icon"
              src="/assets/icons/dashboard/filter.svg"
              style={{ height: "30px", width: "30px" }}
            />

            <Typography variant="h6" sx={{ ml: 1 }}>
              Filters
            </Typography>
          </Box>
          <IconButton onClick={onCloseFilter}>
            <Iconify icon="eva:close-fill" />
          </IconButton>
        </Stack>

        <Divider />

        <Scrollbar>
          <Stack spacing={3} sx={{ p: 3 }}>
            {renderMonthsList}
            {renderDate}
            {renderApplicationName}
            {renderProcessName}
            {renderRunId}
          </Stack>
        </Scrollbar>

        <Box sx={{ p: 3 }}>
          <Button
            fullWidth
            size="large"
            type="submit"
            color="inherit"
            variant="outlined"
            startIcon={<Iconify icon="ic:round-clear-all" />}
          >
            Clear All
          </Button>
        </Box>
      </Drawer>
    </>
  );
}

Filters.propTypes = {
  state: PropTypes.bool,
  onOpenFilter: PropTypes.func,
  onCloseFilter: PropTypes.func,
};
