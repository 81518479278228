import React from "react";
import { Box } from "@mui/material";
import styles from "./style.module.css";
import Header from "./components/header";
import { useState } from "react";
import Summary from "./components/summary";
import { useParams } from "react-router-dom";
import { useContactDetails } from "src/hooks/useContactsData";
import Loader from "src/components/common/loader";

const ContactDetailView = () => {
  const [activeTab, setActiveTab] = useState(0);
  const { id } = useParams();
  const { data, isLoading } = useContactDetails({ id });

  return isLoading ? (
    <Loader />
  ) : (
    <Box>
      {/* <TopHeader styles={styles} /> */}
      <Box className={styles["vertical-spacer"]} />
      <Header
        styles={styles}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        data={data}
      />
      <Box className={styles["vertical-spacer"]} />
      {activeTab === 0 && <Summary styles={styles} data={data} />}
    </Box>
  );
};

export default ContactDetailView;
