import React from 'react';
import TextField from '@mui/material/TextField';

const CustomInput = ({name, label, value, onChange, placeholder, type, ...rest }) => {
  return (
    <TextField
      variant="outlined"
      fullWidth
      label={label}
      placeholder={placeholder}
      type={type}
      name={name}
      value={value}
      onChange={onChange}
      InputProps={{
        sx: {
          '& input': {
            
            height: '1.5rem',
            padding: '10px',
        
          },
          '& fieldset': {
            borderColor: '#ccc',
          },
          
        },
      }}
      InputLabelProps={{
        sx: {
          color: '#666',
          fontSize: '16px',
          fontWeight: 'normal',
          marginBottom: '14px'
        },
      }}
      {...rest}
    />  
  );
};

export default CustomInput;