import { useQuery, useMutation } from "@tanstack/react-query";
import { authorizedGet, authorizedPost, post } from "src/api/base";

export function useSessionData() {
  const { data, isLoading, isError, error } = useQuery({
    queryKey: ["sessionData"],
    queryFn: async () => await authorizedGet({ uri: "/auth/users/me/" }),
  });

  return { data, isLoading, isError, error };
}

export function useLoginMutation() {
  const mutation = useMutation({
    mutationFn: async (data) => await post("/auth/jwt/create/", data),
  });

  return mutation;
}

export function useSignupMutation() {
  const mutation = useMutation({
    mutationFn: async (data) => await post("/auth/users/", data),
  });

  return mutation;
}
export function useResetPassMutation() {
  const mutation = useMutation({
    mutationFn: async (data) => await post("/auth/users/reset_password/", data),
  });

  return mutation;
}
export function useSetNewPassMutation() {
  const mutation = useMutation({
    mutationFn: async ({body}) => await authorizedPost({
      uri: "/auth/users/set_password/",
      params: {},
      body: body,
    }),
  });

  return mutation;
}

export function useConfirmPassMutation() {
  const mutation = useMutation({
    mutationFn: async (data) => await post("/auth/users/reset_password_confirm/", data),
  });

  return mutation;
}