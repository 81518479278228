import React from "react";
import { Box, IconButton, Grid } from "@mui/material";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";

import DealsFilter from "./deals-filters";

const TableHeader = ({
  styles,
  openFilter,
  handleOpenFilter,
  handleCloseFilter,
  value,
  setValue,
  filterData,
  handleFilterDataChange,
  handleFilterReset,
  handleFilterSubmit,
  handleSearch,
  searchField,
  setSearchField,
}) => {
  return (
    <Box sx={{ mb: 2, p: 2, backgroundColor: "white" }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={3}>
          <Paper component="form" className={styles["search-input"]}>
            <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
              <SearchIcon />
            </IconButton>

            <InputBase
              sx={{ ml: 1, flex: 1 }}
              value={searchField}
              onChange={(e) => {
                setSearchField(e.target.value);
                handleSearch();
              }}
              placeholder="Search Deals"
              inputProps={{ "aria-label": "search Deals" }}
            />
          </Paper>
        </Grid>

        <Grid item xs={12} sm={5}></Grid>

        <Grid
          item
          xs={4}
          sm={4}
          sx={{ display: "flex", justifyContent: "end" }}
        >
          <DealsFilter
            openFilter={openFilter}
            onOpenFilter={handleOpenFilter}
            onCloseFilter={handleCloseFilter}
            value={value}
            setValue={setValue}
            filterData={filterData}
            handleFilterDataChange={handleFilterDataChange}
            handleFilterReset={handleFilterReset}
            handleFilterSubmit={handleFilterSubmit}
            styles={styles}
          />
          {/* <Button
            variant="contained"
            onClick={handleOpen}
            className={styles["button"]}
            endIcon={<AddIcon />}
          >
            Add Opportunities
          </Button> */}
        </Grid>
      </Grid>
    </Box>
  );
};

export default TableHeader;
