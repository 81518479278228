import React from "react";
import {
  Box,
  Checkbox,
  Dialog,
  FormControlLabel,
  Typography,
} from "@mui/material";
import { SubmitButton } from "src/components/common/common-button";
import { CommonInputField } from "src/components/common/common-input-field";
import { AttachFileSharp, Close } from "@mui/icons-material";
import { formatFileName } from "src/utils/others";
import { usePatchProposalMutation, usePostProposalMutation } from "src/hooks/useLeadsData";
import { showNotification } from "src/components/notification";
import { useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import RichTextEditor from "src/pages/leads/components/text-editor";


const SendProposal = ({
  styles,
  open,
  proposalFile,
  handleFileChange,
  handleClose,
  formData,
  handleChange,
  editing,
}) => {
  const { id } = useParams();

  //React Query Mutations
  const queryClient = useQueryClient();
  const proposalPostMutation = usePostProposalMutation();
  const proposalPatchMutation = usePatchProposalMutation();
  const getRichTextValue = (value) => {
    handleChange({ target: { name: "body", value } });
  };

  const handleChooseFile = () => {
    document.getElementById("attach-proposal-file").click();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formDataWithFiles = new FormData();
    Object.keys(formData).forEach((key) => {
      console.log(key);
      formDataWithFiles.append(key, formData[key]);
    });
    proposalFile.forEach((file) => {
      formDataWithFiles.append("attachments", file);
    });

    if (editing) {
      await proposalPatchMutation.mutateAsync(
        { body: formDataWithFiles, lead_id: id,id:formData.id },
        {
          onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: ["proposals"] });
            showNotification(
              "success",
              "Proposal Updated Successfully.!",
              2000
            );
            handleClose();
          },
        }
      );
    } else {
      try {
        await proposalPostMutation.mutateAsync(
          { body: formDataWithFiles, id: id },
          {
            onSuccess: (data) => {
              queryClient.invalidateQueries({ queryKey: ["proposals"] });
              showNotification(
                "success",
                "Proposal Added Successfully.!",
                2000
              );
              handleClose();
            },
          }
        );
      } catch (error) {
        showNotification("error", error, 8000);
      }
    }
  };
  const handleCheckboxChange = (event) => {
    handleChange({
      target: { name: "send_email", value: event.target.checked },
    });
  };

  return (
    <Box className={styles["main-dialog"]}>
      <Dialog  open={open === undefined ? false : open}  PaperProps={{
          sx: {
            width: "90%",
            maxWidth: "none",
            height: "fit-content",
            maxHeight: "fit-content",
          },
        }}>
        <Box className={styles["main_header"]}>
          <Box className={styles["add_lead"]}>
            {editing ? "Update" : "Send"} Proposal
          </Box>
          <Box>
            <Close className={styles["close"]} onClick={handleClose} />
          </Box>
        </Box>

        <form onSubmit={handleSubmit} style={{ padding: "20px" }}>
          <CommonInputField
            placeholder="To"
            name="to"
            onChange={handleChange}
            value={formData?.to}
            customClass={styles["proposal-input-phase"]}
          />

          <CommonInputField
            placeholder="CC"
            name="cc"
            onChange={handleChange}
            value={formData?.cc}
            customClass={styles["proposal-input-phase"]}
          />

          <CommonInputField
            placeholder="Subject"
            name="subject"
            onChange={handleChange}
            value={formData?.subject}
            customClass={styles["proposal-input-phase"]}
          />

          {/* <RichTextEditor
            initialValue={formData?.body}
            getValue={getRichTextValue}
          /> */}
          <RichTextEditor
            initialValue={formData?.body}
            getValue={getRichTextValue}
          />

          <Box className={styles["checkbox-container"]}>
            <FormControlLabel
              control={<Checkbox size="small" checked={formData?.send_email}  onChange={handleCheckboxChange} />}
              label="Send An Email"
              sx={{
                "&& .css-1yrymlm-MuiTypography-root": {
                  fontSize: "14px",
                },
              }}
            />
          </Box>

          <Box className={styles["files-section"]}>
            <Box onClick={handleChooseFile} className={styles["attach_file"]}>
              <AttachFileSharp
                className={styles["attach_file_sharp"]}
                sx={{
                  color: "grey",
                  fontSize: "16px",
                  marginRight: "10px",
                  cursor: "pointer",
                }}
              />
              <input
                type="file"
                id="attach-proposal-file"
                onChange={handleFileChange}
                hidden
                multiple
              />
            </Box>

            {proposalFile.length > 0 && (
              <Box className={styles["file-list"]}>
                {proposalFile.map((file, index) => (
                  <Box className={styles["single-file-box"]} key={index}>
                    <Typography>{formatFileName(file.name, 30)}</Typography>
                  </Box>
                ))}
              </Box>
            )}
          </Box>

          <SubmitButton
            fullWidth={true}
            text={editing ? "Update Proposal" : "Send Proposal"}
          />
        </form>
      </Dialog>
    </Box>
  );
};

export default SendProposal;