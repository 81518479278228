import React from "react";
import PropTypes from "prop-types";
import {
  IconButton,
  Typography,
  Box,
  Paper,
  InputBase,
  Grid,
  Button,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import styles from "./style.module.css";

export default function UserListToolbar({
  numSelected,
  query,
  handleChange,
  handleOpenDialog,
}) {
  return (
    <Box sx={{ p: 2, backgroundColor: "white" }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={3}>
          {numSelected > 0 ? (
            <Typography component="div" variant="subtitle1">
              {numSelected} selected
            </Typography>
          ) : (
            <Paper component="form" className={styles["search-input"]}>
              <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
                <SearchIcon />
              </IconButton>

              <InputBase
                sx={{ ml: 1, flex: 1 }}
                defaultValue={query.search}
                onChange={handleChange}
                placeholder="Search For..."
                inputProps={{ "aria-label": "search google maps" }}
                name="search"
              />
            </Paper>
          )}
        </Grid>
        <Grid item xs={12} sm={5}></Grid>
        <Grid
          item
          xs={4}
          sm={4}
          sx={{ display: "flex", justifyContent: "end" }}
        >
          <Button
            variant="contained"
            onClick={handleOpenDialog}
            className={styles["button"]}
            endIcon={<AddIcon />}
          >
            Add User
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}

UserListToolbar.propTypes = {
  numSelected: PropTypes.number,
  handleOpenDialog: PropTypes.func,
};
