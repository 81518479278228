import { Box, Typography } from "@mui/material";
import classNames from "classnames";
import React from "react";
import { CommonInputField } from "src/components/common/common-input-field";

const Address = ({ styles }) => {
  const singleInfo = (name, placeholder, value) => {
    return (
      <Box className={styles["address-single-info"]}>
        <CommonInputField
          name={name}
          value={value}
          handleChange={() => {}}
          placeholder={placeholder}
          customClass={classNames(styles["common-input-div"], "full-width")}
          customInputClass={styles["common-input"]}
          readOnly={true}
        />
      </Box>
    );
  };

  return (
    <Box
      className={classNames("common-box-shadow", styles["account-info-card"])}
    >
      <Typography className={styles["heading"]}>Address Information</Typography>
      {singleInfo("account_name", "Account Name", "A. Datom Corporation")}
      {singleInfo("phone", "Phone", "+91 8978562312")}
      {singleInfo("website", "Website", "www.abc.com")}
    </Box>
  );
};

export default Address;
