import React, { useEffect, useState } from "react";
import { CheckCircle, Close } from "@mui/icons-material";
import {
  CommonButton,
  SubmitButton,
} from "src/components/common/common-button";
import { Box, Dialog } from "@mui/material";
import { CommonInputField } from "src/components/common/common-input-field";
import classNames from "classnames";
import {
  usePatchCloseWinLeadsMutation,
  usePostCloseLeadMutation,
} from "src/hooks/useLeadsData";
import { useParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { showNotification } from "src/components/notification";

const CloseWonModal = ({
  styles,
  open,
  handleClose,
  stepperData,
  formData,
  handleChange,
  editing,
}) => {
  const { id } = useParams();

  // States
  const [missingDetails, setMissingDetails] = useState([]);
  const [showSuccess, setShowSuccess] = useState(false);
  const [continueStrictly, setContinueStrictly] = useState(false);

  // React Query
  const queryClient = useQueryClient();
  const closeWinLeadMutation = usePostCloseLeadMutation();
  const closeWinLeadPatchMutation = usePatchCloseWinLeadsMutation();

  useEffect(() => {
    const temp = [];
    for (let index = 0; index < stepperData.length - 1; index++) {
      const element = stepperData[index];
      if (!element?.isCompleted) {
        temp.push(element?.label);
      }
    }
    setMissingDetails(temp);
  }, [stepperData]); // eslint-disable-line

  const handleCloseWinLead = async () => {
    if (editing) {
      try {
        await closeWinLeadPatchMutation.mutateAsync(
          { body: formData, id: id },
          {
            onSuccess: (data) => {
              queryClient.invalidateQueries({ queryKey: ["close-lead", id] });

              showNotification(
                "success",
                "Lead Closing Updated Successfully.!",
                2000
              );

              handleClose();
            },
          }
        );
      } catch (error) {
        showNotification("error", error, 8000);
      }
    } else {
      try {
        await closeWinLeadMutation.mutateAsync(
          {
            body: { ...formData, status: "won", close_date: new Date() },
            id: id,
          },
          {
            onSuccess: (data) => {
              queryClient.invalidateQueries({ queryKey: ["close-lead", id] });

              showNotification(
                "success",
                "Lead is Secured Successfully.!",
                2000
              );
              setShowSuccess(true);
            },
          }
        );
      } catch (error) {
        showNotification("error", error, 8000);
      }
    }
    setContinueStrictly(false);
  };

  return (
    <Box className={styles["main-dialog"]}>
      <Dialog fullWidth open={open === undefined ? false : open}>
        <Box className={styles["main_header"]}>
          <Box className={styles["add_lead"]}>
            {editing ? "Editing Closing Lead Data" : "Close Won"}
          </Box>
          <Box>
            <Close
              className={styles["close"]}
              onClick={() => {
                handleClose();
                setContinueStrictly(false);
                setShowSuccess(false);
              }}
            />
          </Box>
        </Box>

        {showSuccess ? (
          <>
            <Box
              className={styles["progress_card_name"]}
              sx={{ paddingTop: "10px", textAlign: "center" }}
            >
              Deal Converted
            </Box>

            <Box className={styles["close_tip_card"]}>
              {/* first Tip */}
              <Box
                className={classNames("common-box-shadow", styles["close_tip"])}
              >
                <Box className={styles["proposal_title"]}>
                  <Box
                    className={classNames(
                      "common-box-shadow",
                      styles["tip_img"]
                    )}
                  >
                    <CheckCircle style={{ color: "#177624" }} />
                  </Box>
                  <Box className={styles["requirement_Box"]}>
                    <Box sx={{ display: "flex" }}>
                      <Box className={styles["proposal_title"]}>
                        <Box className={styles["progress_tip_text"]}>
                          All done
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
              {/* second tip */}
              <Box
                className={classNames("common-box-shadow", styles["close_tip"])}
              >
                <Box className={styles["proposal_title"]}>
                  <Box
                    className={classNames(
                      "common-box-shadow",
                      styles["tip_img"]
                    )}
                  >
                    <CheckCircle style={{ color: "#177624" }} />
                  </Box>
                  <Box className={styles["requirement_Box"]}>
                    <Box sx={{ display: "flex" }}>
                      <Box className={styles["proposal_title"]}>
                        <Box className={styles["progress_tip_text"]}>
                          Contact created
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
              {/* third Tip */}
              <Box
                className={classNames("common-box-shadow", styles["close_tip"])}
              >
                <Box className={styles["proposal_title"]}>
                  <Box
                    className={classNames(
                      "common-box-shadow",
                      styles["tip_img"]
                    )}
                  >
                    <CheckCircle style={{ color: "#177624" }} />
                  </Box>
                  <Box className={styles["requirement_Box"]}>
                    <Box sx={{ display: "flex" }}>
                      <Box className={styles["proposal_title"]}>
                        <Box className={styles["progress_tip_text"]}>
                          Now you can see the LEAD converted under DEAL tab.
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>

              <SubmitButton
                fullWidth={true}
                text="Close"
                handleClick={() => {
                  handleClose();
                  setShowSuccess(false);
                }}
              />
            </Box>
          </>
        ) : (missingDetails && missingDetails.length === 0) ||
          continueStrictly ? (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              if (editing) {
                handleCloseWinLead(true);
              } else {
                handleCloseWinLead();
              }
            }}
            style={{ padding: "20px" }}
          >
            <CommonInputField
              placeholder="Closing Amount"
              name="closing_value"
              type="text"
              defaultValue={formData?.closing_value}
              handleChange={handleChange}
              customClass={"full-width"}
            />
            <CommonInputField
              placeholder="Comment"
              name="closing_comment"
              type="text"
              defaultValue={formData?.closing_comment}
              handleChange={handleChange}
              minLines={4}
              customClass={classNames("full-width", styles["text-area"])}
            />
            {/* <Box className={styles["analysis_main"]}>
              <img
                src="/assets/tick.gif"
                alt="Your GIF description"
                style={{ marginBottom: "10px" }}
              />
            </Box> */}

            <SubmitButton fullWidth={true} text="Close Lead as Win" />
          </form>
        ) : (
          <Box className={styles["strict-go-ahead"]}>
            <Box className={styles["suucess_lead_img_box"]}>
              <img
                src="/assets/question-mark.png"
                alt="gg"
                className={styles["suucess_lead_img"]}
              />
            </Box>
            <Box className={styles["close_won_msg_box"]}>
              <Box>
                <Box className={styles["close_won_msg"]}>
                  Do you really want to close the lead?
                </Box>
                <Box className={styles["close_won_msg"]}>
                  You have not completed the below steps:
                </Box>
                {missingDetails.map((item, index) => (
                  <Box className={styles["warning_lead_img_box"]}>
                    <Box>
                      <img
                        src="/assets/warning.png"
                        alt=""
                        className={styles["warning_lead_img"]}
                      />
                    </Box>
                    <Box key={index}>{item}</Box>
                  </Box>
                ))}
              </Box>
            </Box>

            <Box className={styles["submit-btns"]}>
              <CommonButton
                text={"Yes"}
                handleClick={(e) => {
                  e.preventDefault();
                  setContinueStrictly(true);
                }}
              />
              <CommonButton
                text={"Cancel"}
                customClass={styles["cancel-btn"]}
                handleClick={(e) => {
                  e.preventDefault();
                  handleClose();
                }}
              />
            </Box>
          </Box>
        )}
      </Dialog>
    </Box>
  );
};

export default CloseWonModal;
