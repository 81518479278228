import { useQuery, useMutation } from "@tanstack/react-query";
import {
  authorizedDelete,
  authorizedGet,
  authorizedPatch,
  authorizedPost,
  authorizedUploadFile,
} from "src/api/base";

export function useDealsData({ page, query }) {
  
  const { data, isLoading, isError, error } = useQuery({
    queryKey: ["dealsData"],
    queryFn: async () =>
      await authorizedGet({
        uri: `/api/v1/leads/?status=won`,
      }),
  });

  return { data, isLoading, isError, error };
}

export function useDealsDetails({ id }) {
  const { data, isLoading, isError, error } = useQuery({
    queryKey: ["opportunityDetail", id],
    queryFn: async () =>
      await authorizedGet({ uri: `/api/v1/opportunities/${id}` }),
  });

  return { data, isLoading, isError, error };
}

export function useDealsMutation() {
  const mutation = useMutation({
    mutationFn: async (data) =>
      await authorizedPost({ uri: "/api/v1/leads/", params: {}, body: data }),
  });

  return mutation;
}

export function useDealsNotesMutation() {
  const mutation = useMutation({
    mutationFn: async (data) =>
      await authorizedPost({
        uri: `/api/v1/leads/${data?.id}/notes/`,
        params: {},
        body: data,
      }),
  });

  return mutation;
}
export function useDealsNotesEditMutation() {
  const mutation = useMutation({
    mutationFn: async (data) =>
      await authorizedPatch({
        uri: `/api/v1/leads/notes/${data?.notes_id}/`,
        params: {},
        body: data,
      }),
  });
  return mutation;
}
export function useDealsAttachmentMutation() {
  const mutation = useMutation({
    mutationFn: async (data) => {
      return await authorizedUploadFile({
        uri: `/api/v1/leads/${data?.id}/attachments/`,
        params: {},
        body: data?.data,
      });
    },
  });

  return mutation;
}

export function useDeleteDealsAttachmentMutation() {
  const mutation = useMutation({
    mutationFn: async (data) => {
      return await authorizedDelete({
        uri: `/api/v1/leads/attachments/${data?.attachment_id}/`,
        params: {},
      });
    },
  });

  return mutation;
}

export function useDeleteDealsNoteMutation() {
  const mutation = useMutation({
    mutationFn: async (data) => {
      return await authorizedDelete({
        uri: `/api/v1/leads/notes/${data?.note_id}/`,
        params: {},
      });
    },
  });

  return mutation;
}

export function useDealsUpdateMutation() {
  const mutation = useMutation({
    mutationFn: async (data) => {
      return await authorizedPatch({
        uri: `/api/v1/leads/${data?.id}/`,
        params: {},
        body: data,
      });
    },
  });

  return mutation;
}

export function useDealsToDealsMutation() {
  const mutation = useMutation({
    mutationFn: async (data) => {
      return await authorizedPost({
        uri: `/api/v1/opportunities/`,
        params: {},
        body: data,
      });
    },
  });

  return mutation;
}

export function useDeleteDealsMutation() {
  const mutation = useMutation({
    mutationFn: async (id) =>
      await authorizedDelete({ uri: `/api/v1/leads/${id}` }),
  });

  return mutation;
}

export function usePatchDealsMutation() {
  const mutation = useMutation({
    mutationFn: async (body) => {
      return await authorizedPatch({
        uri: `/api/v1/opportunities/${body?.id}/`,
        params: {},
        body: body,
      });
    },
  });

  return mutation;
}
