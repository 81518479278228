import { Box, Typography } from "@mui/material";
import classNames from "classnames";
import React from "react";
import NameProfilePic from "src/components/common/name-profile-pic";

const Header = ({ styles, activeTab, setActiveTab,data }) => {
  return (
    <Box className={classNames("common-box-shadow", styles["header-main-div"])}>
      <Box className={styles["account-details"]}>
        <Box className={styles["profile-info"]}>
          <NameProfilePic
              letter={
                data?.name
                  ? data?.name[0].toUpperCase()
                  : ""
              }
            customClass={styles["profile-icon"]}
            customStyle={{
              fontSize: "13px !important",
            }}
          />
          <Box>
            <Typography className={styles["account-name"]}>
              {data?.name}
            </Typography>
          </Box>
        </Box>
        <Box className={styles["owner-info"]}>
          <Box className={styles["annual-revenue"]}>
            <Typography className={styles["data"]}>$ 350000</Typography>
            <Typography className={styles["header"]}>Annual Revenue</Typography>
          </Box>
          <Box
            className={styles["border"]}
            sx={{ height: "42px !important" }}
          />
          <Box className={styles["account-owner-profile"]}>
            <NameProfilePic
              //   letter={
              //     row?.lead_detail?.first_name
              //       ? row?.lead_detail?.first_name[0].toUpperCase()
              //       : ""
              //   }
              letter={"S"}
              customClass={styles["profile-icon"]}
              customStyle={{
                fontSize: "11px !important",
              }}
            />
            <Box className={styles["annual-revenue"]}>
              <Typography className={styles["data"]}>Sagar Sharma</Typography>
              <Typography className={styles["header"]}>Owner</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box className={styles["tab-switcher"]}>
        {["Summary", "Relationship Analytics", "Details", "Related"].map(
          (item, index) => (
            <Box
              key={index}
              onClick={() => setActiveTab(index)}
              className={classNames(
                styles["single-tab"],
                activeTab === index && styles["active-tab-content"]
              )}
            >
              <Box key={index}>
                <Box>{item}</Box>
                {activeTab === index && (
                  <Box
                    className={
                      activeTab === index
                        ? styles["active-tab"]
                        : styles["unactive-tab"]
                    }
                  ></Box>
                )}
              </Box>
            </Box>
          )
        )}
      </Box>
    </Box>
  );
};

export default Header;
