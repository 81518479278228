import { Box, Typography } from "@mui/material";
import classNames from "classnames";
import React from "react";
import { CommonInputField } from "src/components/common/common-input-field";

const ContactInfo = ({ styles,data }) => {
  const singleInfo = (name, placeholder, value,) => {
    return (
      <Box className={styles["single-info"]}>
        <Box className={styles["title"]}>
          <Typography className={styles["title-text"]}> {placeholder}</Typography>
        </Box>
        <Box className={styles["input-box"]}>
          <CommonInputField
            name={name}
            value={value}
            handleChange={() => {}}
            placeholder={placeholder}
            customClass={styles["common-input-div"]}
            readOnly={true}
          />
        </Box>
      </Box>
    );
  };
  return (
    <Box
      className={classNames("common-box-shadow", styles["account-info-card"])}
    >
      <Typography className={styles["heading"]}>Contact Information</Typography>
      {singleInfo("account_name", "Account Name", data?.name)}
      {singleInfo("phone", "Phone", data?.number)}
      {singleInfo("email", "Email", data?.email)}
      {singleInfo("designation", "Designation", data?.designation)}
      {singleInfo("decision_maker", "Decision Maker", data?.is_decision_maker ? "Yes" : "No")}
    </Box>
  );
};

export default ContactInfo;
