import { Email, Language, Phone } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import classNames from "classnames";
import React from "react";
import { CommonInputField } from "src/components/common/common-input-field";

const AccountInfo = ({ styles, data }) => {
  const singleInfo = (name, placeholder, value, endIcon = false) => {
    return (
      <Box className={styles["single-info"]}>
        <Box className={styles["title"]}>
          <Typography className={styles["title-text"]}>
            {placeholder}
          </Typography>
        </Box>
        <Box className={styles["input-box"]}>
          <CommonInputField
            name={name}
            value={value}
            handleChange={() => {}}
            placeholder={placeholder}
            customClass={styles["common-input-div"]}
            customInputClass={styles["common-input"]}
            readOnly={true}
          />
          {endIcon && endIcon}
        </Box>
      </Box>
    );
  };
  return (
    <Box
      className={classNames("common-box-shadow", styles["account-info-card"])}
    >
      <Typography className={styles["heading"]}>Account Information</Typography>
      {singleInfo("account_name", "Account Name", data?.name)}
      {singleInfo(
        "phone",
        "Phone",
        data?.phone,
        <Box className={styles["end-icon"]}>
          <Phone fontSize="14px" />
        </Box>
      )}
       {singleInfo(
        "email",
        "Email",
        data?.email,
        <Box className={styles["end-icon"]}>
          <Email fontSize="14px" />
        </Box>
      )}
      {singleInfo(
        "website",
        "Website",
        data?.website,
        <Box className={styles["end-icon"]}>
          <Language fontSize="14px" />
        </Box>
      )}
      {singleInfo("parent_account", "Parent Account", "----")}
    </Box>
  );
};

export default AccountInfo;
