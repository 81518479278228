import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, IconButton } from '@mui/material';
import { bgBlur } from '../../../utils/cssStyles';
import Iconify from '../../../components/iconify';
import AccountPopover from './AccountPopover';
import NotificationsPopover from './NotificationsPopover';
import Logo from 'src/components/logo';

const NAV_WIDTH = 240;
const HEADER_MOBILE = 64;
const HEADER_DESKTOP = 70;



const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: HEADER_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: HEADER_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

Header.propTypes = {
  onOpenNav: PropTypes.func,
};

export default function Header({ openNav, onOpenNav }) {
  const StyledRoot = styled(AppBar)(({ theme }) => ({
    // ...bgBlur({ color: theme.palette.background.default }),
    ...bgBlur({ color: "#ffff" }),
    boxShadow: 'none',
    [theme.breakpoints.up('lg')]: {
      width: openNav ? `calc(100% - ${NAV_WIDTH + 1}px)` : "100%",
    },
  }));

  return (
    <StyledRoot>
      <StyledToolbar>
      {!openNav && <Box sx={{ px: 2.5, display: 'inline-flex' }}>
        <Logo openNav={openNav}/>
      </Box>}
        <IconButton
          onClick={onOpenNav}
          sx={{
            mr: 1,
            color: 'text.primary',
          }}
        >
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>

        <Box sx={{ flexGrow: 1 }} />

        <Stack
          direction="row"
          alignItems="center"
          spacing={{
            xs: 0.5,
            sm: 1,
          }}
        >
          <NotificationsPopover />
          <AccountPopover />
        </Stack>
      </StyledToolbar>
    </StyledRoot>
  );
}
