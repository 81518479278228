import React from "react";
import { Helmet } from "react-helmet-async";
import { styled } from "@mui/material/styles";
import { Container, Typography, Grid } from "@mui/material";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Logo from "../../../components/logo";
import { useSignupMutation } from "../../../hooks/useSessiondata";
import { showNotification } from "../../../components/notification";
import "react-toastify/dist/ReactToastify.css";
import { AuthForm } from "src/sections/auth/login";

const StyledRoot = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const StyledContent = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  height: "100vh",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(12, 0),
}));

const SignupPage = () => {
  const signupMutation = useSignupMutation();

  const navigate = useNavigate();

  const handleSignup = async (email, password, fullname, organization) => {
    try {
      await signupMutation.mutateAsync(
        { email, password, fullname, organization },
        {
          onSuccess: (data) => {
            localStorage.setItem("fullname", fullname);
            showNotification("success", "Welcome", 2000);
            navigate("/login");
          },
        }
      );
    } catch (error) {
      showNotification("error", error, 8000);
    }
  };
  return (
    <>
      <ToastContainer />
      {signupMutation.isLoading}
      <Helmet>
        <title> Sign up | Lead Management System </title>
      </Helmet>

      <StyledRoot>
        <Container maxWidth="sm">
          <StyledContent>
            <Grid
              sx={{
                display: "flex",
                justifyContent: "center",
                paddingBottom: "20px",
              }}
            >
              <Logo />
            </Grid>
            <Grid sx={{ textAlign: "center", marginBottom: "50px" }}>
              <Typography variant="h4" gutterBottom>
                Sign up to Lead Management System
              </Typography>
            </Grid>
            <AuthForm type="signup" handleUser={handleSignup} />
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
};

export default SignupPage;
