import React from "react";
import { Box, IconButton, Button, Grid } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import LeadFilters from "./lead-filters";
import { FileDownload } from "@mui/icons-material";
import classNames from "classnames";

const TableHeader = ({
  styles,
  handleOpen,
  openFilter,
  handleOpenFilter,
  handleCloseFilter,
  filterData,
  handleFilterDataChange,
  handleFilterReset,
  selectedCount,
  handleDeleteSelected,
  query,
  handleChange,
}) => {
  return (
    <Box sx={{ p: 2, backgroundColor: "white" }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={3}>
          <Paper component="form" className={styles["search-input"]}>
            <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
              <SearchIcon />
            </IconButton>

            <InputBase
              sx={{ ml: 1, flex: 1 }}
              name="search"
              defaultValue={query.search}
              onChange={handleChange}
              placeholder="Search Leads"
              inputProps={{ "aria-label": "search leads" }}
            />
          </Paper>
        </Grid>

        <Grid item xs={12} sm={5}></Grid>

        <Grid
          item
          xs={4}
          sm={4}
          sx={{ display: "flex", justifyContent: "end" }}
        >
          {selectedCount > 0 ? (
            <IconButton
              onClick={handleDeleteSelected}
              sx={{ p: "10px", color: "red" }}
              aria-label="delete selected"
            >
              <DeleteForeverIcon />
            </IconButton>
          ) : (
            <>
              <Button
                variant="contained"
                className={styles["button"]}
                endIcon={<FileDownload />}
              >
                Export
              </Button>
              <LeadFilters
                openFilter={openFilter}
                onOpenFilter={handleOpenFilter}
                onCloseFilter={handleCloseFilter}
                filterData={filterData}
                handleFilterReset={handleFilterReset}
                handleFilterDataChange={handleFilterDataChange}
                styles={styles}
              />
              <Button
                variant="contained"
                onClick={handleOpen}
                className={classNames(styles["button"], styles["create-btn"])}
                endIcon={<AddIcon />}
              >
                Create Lead
              </Button>
            </>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default TableHeader;
