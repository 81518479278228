import { Phone } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import classNames from "classnames";
import React from "react";
import { CommonInputField } from "src/components/common/common-input-field";

const KeyDetails = ({ styles, data }) => {
  const singleInfo = (
    name,
    placeholder,
    value,
    endIcon = false,
    minLines = 1
  ) => {
    return (
      <Box className={styles["single-info"]}>
        <Box className={styles["title"]}>
          <Typography className={styles["title-text"]}>
            {placeholder}
          </Typography>
        </Box>
        <Box className={styles["input-box"]}>
          <CommonInputField
            name={name}
            value={value}
            handleChange={() => {}}
            placeholder={placeholder}
            customClass={styles["common-input-div"]}
            customInputClass={styles["common-input"]}
            readOnly={true}
            minLines={minLines}
          />
          {endIcon && endIcon}
        </Box>
      </Box>
    );
  };

  return (
    <Box
      className={classNames("common-box-shadow", styles["account-info-card"])}
    >
      <Typography className={styles["heading"]}>Key Details</Typography>
      {singleInfo("topic", "Topic", " 1 Pro Gronders  Northwind traders")}
      {singleInfo(
        "contact",
        "Contact",
        data?.lead_detail?.phone,
        <Box className={styles["end-icon"]}>
          <Phone fontSize="14px" />
        </Box>
      )}
      {singleInfo("purchase-timeframe", "PurchaseTimeframe", "Unknown")}
      {singleInfo("currency", "Currency", "Indian Rupees")}
      {singleInfo("budget-amount", "Budget Amount", data?.amount)}
      {singleInfo("purchase-process", "Purchase Process", "Unknown")}
    </Box>
  );
};

export default KeyDetails;
