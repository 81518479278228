import React from "react";
import { Box, Dialog } from "@mui/material";
import { Close } from "@mui/icons-material";
import { CommonInputField } from "src/components/common/common-input-field";
import { SubmitButton } from "src/components/common/common-button";
import { CommonDropdownField } from "src/components/common/common-dropdown-field";
import { STAGE_OPTIONS } from "src/utils/constants";

const DealsModal = ({
  styles,
  formData,
  open,
  edit,
  handleClose,
  handleReset,
  handleChange,
  handleSubmit,
  setEdit,
}) => {
  return (
    <Box className={styles["main"]}>
      <Dialog open={open}>
        <Box className={styles["main_header"]}>
          <Box className={styles["add_lead"]}>
            {edit ? "EDIT OPPORTUNITY" : "ADD OPPORTUNITY"}
          </Box>
          <Box>
            <Close
              className={styles["close"]}
              onClick={() => {
                handleClose();
                handleReset();
                setEdit(false);
              }}
            />
          </Box>
        </Box>
        {/* AVATAR IMAGE */}
        {/* <Box className={styles["img-main"]}>
          <Box className={styles["img_inner"]}>
            <Box className={styles["avatar"]}>
              <img src="/assets/images/avatars/user2.svg" alt="user avatar" />
            </Box>

            <Typography variant="h6">Lead Image</Typography>
          </Box>
        </Box> */}
        <form onSubmit={handleSubmit} style={{ padding: "20px" }}>
          <Box className={styles["text_field"]}>
            < putField
              placeholder="Opportunity Amount"
              name="amount"
              type="number"
              value={formData.amount}
              handleChange={handleChange}
            />
            <CommonInputField
              placeholder="Probability"
              name="probability"
              type="text"
              value={formData.probability}
              handleChange={handleChange}
              customClass={styles["common-search-input-right"]}
            />
          </Box>
          <Box className={styles["text_field"]}>
            <CommonDropdownField
              handleChange={handleChange}
              defaultValue={"default"}
              name="stage"
              placeholder="Stage"
              variant="outlined"
              value={formData?.stage}
              options={STAGE_OPTIONS}
              customClass={"full-width"}
            />
          </Box>

          <CommonInputField
            placeholder="Description"
            name="description"
            type="text"
            value={formData.description}
            handleChange={handleChange}
            styles={styles}
            customClass={styles["common-search-input-right"]}
          />

          <SubmitButton
            fullWidth={true}
            text={edit ? "Update Opportunity" : "Add Opportunity"}
            onClick={handleSubmit}
          />
        </form>
      </Dialog>
    </Box>
  );
};

export default DealsModal;
