import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

const styles = {
  card: {
    px: 1.5,
    py: 2.5,
    borderRadius: 2,
    height: "100%",
  },
  iconBox: { width: 30, height: 30 },
  title: { fontSize: "20px", fontWeight: "bolder" },
  subtitle: { color: "black", fontSize: "13px" },
};

export default function StatsCard({ title, total, icon }) {
  return (
    <Card component={Stack} spacing={2} direction="row" sx={styles?.card}>
      {icon && <Box sx={styles?.iconBox}>{icon}</Box>}
      <Stack spacing={0.2} direction="column" alignItems="start">
        <Typography sx={styles?.title}>{total}</Typography>
        <Typography variant="subtitle2" sx={styles?.subtitle}>
          {title}
        </Typography>
      </Stack>
    </Card>
  );
}

StatsCard.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  title: PropTypes.string,
  total: PropTypes.string,
};
