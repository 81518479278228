import React from "react";
import { Box } from "@mui/material";
import classNames from "classnames";
import { Call, Email, Visibility } from "@mui/icons-material";

const IdentifyStakeHolderPhase = ({
  styles,
  stakeholderData,
  setFormData,
  handleEditModalOpen,
}) => {
  return (
    <Box className={styles["analysis_main"]}>
      {stakeholderData?.contact?.length === 0 || !stakeholderData ? (
        <Box className={styles["analysis_box_main"]}>
          Add Stakeholder Or Any Contact
        </Box>
      ) : (
        stakeholderData?.contact.map((item, index) => (
          <Box
            className={classNames("common-box-shadow", styles["progress_card"])}
            key={index}
          >
            <Box className={styles["progress_card_title"]}>
              <Box
                className={classNames(
                  "common-box-shadow-third",
                  styles["card_icon"]
                )}
              >
                <img
                  src="/assets/coordination.png"
                  alt=""
                  className={styles["progress_card_img"]}
                />
              </Box>
              <Box className={styles["card_content"]}>
                <Box className={styles["card_title"]}>
                  <Box className={styles["progress_card_name"]}>
                    {item?.name}
                  </Box>
                  <Box className={styles["is_decision_maker_box"]}>
                    <Box className={styles["is_decision_maker"]}>
                      {item?.is_decision_maker
                        ? "Decision Maker"
                        : "Non Decision Maker"}
                    </Box>
                    <Box
                      className={styles["visibility"]}
                      onClick={() => {
                        handleEditModalOpen();
                        setFormData({ ...item });
                      }}
                    >
                      <Visibility
                        className={styles["progress_card_icon_sec"]}
                      />
                    </Box>
                  </Box>
                </Box>
                <Box className={styles["card_title"]}>
                  <Box className={styles["progress_card_job"]}>
                    {item?.designation}
                  </Box>

                  {/* <Box className={styles["progress_card_title"]}>
                    <Box className={styles["progress_conatct"]}>
                      Contact Name:{" "}
                    </Box>
                    <Box className={styles["progress_card_job"]}>
                      Kajal Rituraj
                    </Box>
                  </Box> */}
                </Box>
                <Box className={styles["card_title"]}>
                  <Box className={styles["progress_card_title"]}>
                    <Box className={styles["progress_card_title"]}>
                      <Email className={styles["progress_card_icon"]} />
                      <Box className={styles["progress_card_icon_text"]}>
                        {item?.email}
                      </Box>
                    </Box>
                    <Box className={styles["progress_card_title"]}>
                      <Call className={styles["progress_card_icon"]} />
                      <Box className={styles["progress_card_icon_text"]}>
                        {item?.number}
                      </Box>
                    </Box>
                  </Box>
                  <Box className={styles["progress_card_job"]}>
                    22/02/2024 09:03PM
                  </Box>
                </Box>
                <Box className={styles["progress_card_desc"]}>
                  {item?.comment}
                </Box>
              </Box>
            </Box>
          </Box>
        ))
      )}

      {/* <Box className={classNames("common-box-shadow", styles["progress_card"])}>
        <Box className={styles["progress_card_title"]}>
          <Box
            className={classNames(
              "common-box-shadow-third",
              styles["card_icon"]
            )}
          >
            <img
              src="/assets/stake2.png"
              alt=""
              className={styles["progress_card_img"]}
            />
          </Box>
          <Box className={styles["card_content"]}>
            <Box className={styles["card_title"]}>
              <Box className={styles["progress_card_name"]}>Devid Miller</Box>
              <Box className={styles["is_decision_maker_box"]}>
                <Box className={styles["non_decision_maker"]}>
                  Non Decision Maker
                </Box>
                <Box className={styles["visibility"]}>
                  <Visibility className={styles["progress_card_icon_sec"]} />
                </Box>
              </Box>
            </Box>
            <Box className={styles["card_title"]}>
              <Box className={styles["progress_card_job"]}>Web Developer</Box>

              <Box className={styles["progress_card_title"]}>
                <Box className={styles["progress_conatct"]}>Contact Name: </Box>
                <Box className={styles["progress_card_job"]}> Jhon Deo</Box>
              </Box>
            </Box>
            <Box className={styles["card_title"]}>
              <Box className={styles["progress_card_title"]}>
                <Box className={styles["progress_card_title"]}>
                  <Email className={styles["progress_card_icon"]} />
                  <Box className={styles["progress_card_icon_text"]}>
                    abc@example.com
                  </Box>
                </Box>
                <Box className={styles["progress_card_title"]}>
                  <Call className={styles["progress_card_icon"]} />
                  <Box className={styles["progress_card_icon_text"]}>
                    +91 87987978798
                  </Box>
                </Box>
              </Box>
              <Box className={styles["progress_card_job"]}>
                22/02/2024 09:03PM
              </Box>
            </Box>
            <Box className={styles["progress_card_desc"]}>
              This handy tool helps you create dummy text for all your layout
              needs...
            </Box>
          </Box>
        </Box>
      </Box> */}
    </Box>
  );
};

export default IdentifyStakeHolderPhase;
