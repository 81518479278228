import React from "react";
import ReactApexChart from "react-apexcharts";
import { useTheme } from "@mui/material/styles";
import { Card, CardHeader, Typography } from "@mui/material";
import { useChart } from "../../../components/chart";

const styles = {
  card: { height: "98%" },
  title: { fontSize: "14px", textAlign: "center", fontWeight: "bold" },
};

const GradientDonut = ({ title, subheader, chartData }) => {
  const theme = useTheme();
  const chartLabels = chartData.map((i) => i.label);
  const chartSeries = chartData.map((i) => i.value);

  const gradientChartColors = ["#ABD4F7", "#6CB4F1", "#1F68A5", "#123B5E"];

  const chartOptions = useChart({
    colors: chartData.map(
      (item, index) => gradientChartColors[index % gradientChartColors.length]
    ),
    labels: chartLabels,
    stroke: { colors: [theme.palette.background.paper], width: 0.8 },
    legend: {
      show: true,
      position: "bottom",
      horizontalAlign: "center",
      onItemClick: {
        toggleDataSeries: true,
      },
    },
    plotOptions: {
      pie: { donut: { labels: { show: false } } },
    },
  });

  return (
    <Card sx={styles?.card}>
      <CardHeader
        title={
          <Typography
            sx={{ fontSize: "14px", textAlign: "center", fontWeight: "bold"}}
          >
            {title}
          </Typography>
        }
        sx={{paddingTop: "15px"}}
        subheader={subheader}
      />
      <ReactApexChart
        options={chartOptions}
        series={chartSeries}
        type="donut"
        height={260}
      />
    </Card>
  );
};
export default GradientDonut;