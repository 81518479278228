import React from "react";
import { Box, Dialog } from "@mui/material";
import { Close } from "@mui/icons-material";
import { CommonInputField } from "src/components/common/common-input-field";
import { SubmitButton } from "src/components/common/common-button";
import { CommonDropdownField } from "src/components/common/common-dropdown-field";
import { SOURCE_OPTIONS } from "src/utils/constants";
import classNames from "classnames";

const LeadModal = ({
  styles,
  formData,
  open,
  edit,
  handleClose,
  handleReset,
  handleChange,
  handleSubmit,
  setEdit,
  selectedRow,
}) => {
  return (
    <Box className={styles["main"]}>
      <Dialog open={open} fullWidth>
        <Box className={styles["main_header"]}>
          <Box className={styles["add_lead"]}>
            {edit ? "EDIT LEAD" : "CREATE LEAD"}
          </Box>
          <Box>
            <Close
              className={styles["close"]}
              onClick={() => {
                handleClose();
                setTimeout(() => {
                  handleReset();
                  setEdit(false);
                }, 500);
              }}
            />
          </Box>
        </Box>
        <form onSubmit={handleSubmit} style={{ padding: "20px" }}>
          <Box className={styles["text_field"]}>
            <CommonInputField
              placeholder="First name"
              name="first_name"
              maxRows={1}
              defaultValue={selectedRow?.first_name}
              handleChange={handleChange}
              customClass={styles["fixed-width"]}
            />
            <CommonInputField
              placeholder="Last name"
              name="last_name"
              maxRows={1}
              defaultValue={selectedRow?.last_name}
              handleChange={handleChange}
              customClass={classNames(
                styles["common-search-input-right"],
                styles["fixed-width"]
              )}
            />
          </Box>

          <Box className={styles["text_field"]}>
            <CommonInputField
              placeholder="Email"
              name="email"
              type="email"
              defaultValue={selectedRow?.email}
              handleChange={handleChange}
              customClass={styles["fixed-width"]}
              maxRows={1}
              styles={styles}
            />
            <CommonInputField
              placeholder="Phone"
              name="phone"
              defaultValue={selectedRow?.phone}
              handleChange={handleChange}
              styles={styles}
              maxRows={1}
              customClass={classNames(
                styles["common-search-input-right"],
                styles["fixed-width"]
              )}
            />
          </Box>
          <Box className={styles["text_field"]}>
            <CommonInputField
              placeholder="Opportunity Amount"
              name="opportunity_amount"
              type="number"
              maxRows={1}
              customClass={styles["fixed-width"]}
              defaultValue={selectedRow?.opportunity_amount}
              handleChange={handleChange}
            />
            <CommonInputField
              placeholder="Probability"
              name="probability"
              type="text"
              maxRows={1}
              defaultValue={selectedRow?.probability}
              handleChange={handleChange}
              customClass={classNames(
                styles["common-search-input-right"],
                styles["fixed-width"]
              )}
            />
          </Box>
          <Box className={styles["text_field"]}>
            <CommonInputField
              placeholder="Organization"
              name="company"
              type="text"
              defaultValue={selectedRow?.company}
              handleChange={handleChange}
              maxRows={1}
              customClass={styles["fixed-width"]}
            />

            <CommonDropdownField
              handleChange={handleChange}
              name="source"
              placeholder="Source"
              variant="outlined"
              defaultValue={selectedRow?.source}
              options={SOURCE_OPTIONS}
              customClass={"half-width"}
            />
          </Box>

          <CommonInputField
            placeholder="Website"
            name="website"
            type="text"
            defaultValue={selectedRow?.website}
            handleChange={handleChange}
            styles={styles}
            maxRows={1}
            customClass={styles["common-search-input-right"]}
          />

          <SubmitButton
            fullWidth={true}
            text={edit ? "Update Lead" : "Create Lead"}
            onClick={handleSubmit}
          />
        </form>
      </Dialog>
    </Box>
  );
};

export default LeadModal;
