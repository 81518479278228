import React from "react";
import { Box, Dialog } from "@mui/material";
import { Close } from "@mui/icons-material";
import { SubmitButton } from "src/components/common/common-button";
import { CommonInputField } from "src/components/common/common-input-field";

const NotesModal = ({
  styles,
  open,
  handleClose,
  notesData,
  handleSubmit,
  handleChange,
}) => {
  return (
    <Box className={styles["main-dialog"]}>
      <Dialog open={open} fullWidth>
        <Box className={styles["main_header"]}>
          <Box className={styles["add_lead"]}>
            {notesData?.editing ? "Edit Note" : "Add Note"}
          </Box>
          <Box>
            <Close className={styles["close"]} onClick={() => handleClose()} />
          </Box>
        </Box>
        <form onSubmit={handleSubmit} style={{ padding: "20px" }}>
          <CommonInputField
            placeholder="Title"
            name="title"
            customClass={styles["text-area"]}
            value={notesData?.message?.title}
            onChange={handleChange}
          />
          <CommonInputField
            placeholder="Description"
            name="description"
            customClass={styles["text-area"]}
            value={notesData?.message?.description}
            onChange={handleChange}
            minLines={4}
          />
          <SubmitButton
            fullWidth={true}
            text={notesData?.editing ? "Update Note" : "Add Note"}
            onClick={handleSubmit}
          />
        </form>
      </Dialog>
    </Box>
  );
};

export default NotesModal;
