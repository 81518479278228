import React from "react";
import { Box } from "@mui/material";
import classNames from "classnames";
import { Visibility } from "@mui/icons-material";
import { formatDate } from "src/utils/formatTime";

const ProposalCard = ({ styles, data, index, handleClick }) => {
  return (
    <Box
      className={classNames("common-box-shadow", styles["proposal_card_first"])}
      key={index}
      onClick={handleClick}
    >
      <Box className={styles["card_title"]}>
        <Box className={styles["non_decision_maker"]}>Proposal {index + 1}</Box>
        <Box className={styles["visibility"]}>
          <Visibility className={styles["progress_card_icon_sec"]} />
        </Box>
      </Box>

      <Box className={styles["proposal_title"]}>
        <Box className={classNames("common-box-shadow", styles["to_avtar"])}>
          <img
            src="/assets/user_to.png"
            alt=""
            className={styles["user_to_img"]}
          />
        </Box>
        <Box>
          <Box className={styles["proposal_to_name"]}>{data?.to}</Box>
          {data?.cc && (
            <Box className={styles["proposal_title"]}>
              <Box className={styles["cc"]}> Cc</Box>
              <Box className={styles["progress_card_icon_text"]}>
                {data?.cc}
              </Box>
            </Box>
          )}
        </Box>
      </Box>
      <Box className={styles["subject_proposal"]}>
        <Box className={styles["subject_proposal_text"]}>Subject:</Box>
        <Box>
          {data?.subject?.substring(0, 53)}
          {data?.subject?.length > 53 && " ..."}
        </Box>
      </Box>
      <Box className={styles["proposal_date"]}>
        <Box className={styles["progress_card_job"]}>
          {formatDate(data?.updated_at)}
        </Box>
      </Box>
    </Box>
  );
};

export default ProposalCard;
