import React from "react";
import dayjs from "dayjs";
import { useOutletContext } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useTheme } from "@mui/material/styles";
import { Grid, Container, Box, Typography } from "@mui/material";
import TaskBySite from "./components/tasks-by-site";
import GradientDonut from "./components/gradient-donut";
import StatsCard from "./components/stats-card";
import Filters from "./components/filters";
import { useEffect, useState } from "react";
import ExportButton from "./components/export";

import ColumnChart from "./components/column-chart";
import FunnelChart from "./components/funnel-chart";

const DashboardAppPage = () => {
  const { setOpen } = useOutletContext();
  const theme = useTheme();
  const [selectedMonth, setSelectedMonth] = useState("jan");
  const [value, setValue] = React.useState(dayjs());

  const [openFilter, setOpenFilter] = useState(false);

  const handleOpenFilter = () => {
    setOpenFilter(true);
    setOpen(false);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };

  useEffect(() => {
    const monthInThreeLetters = value.format("MMM").toLowerCase();
    setSelectedMonth(monthInThreeLetters);
  }, [value]);

  return (
    <>
      <Helmet>
        <title> Dashboard | Lead Management </title>
      </Helmet>
      <Container maxWidth="xxl">
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h4" sx={{ mb: 2 }}>
            Dashboard - {new Date().toDateString()}
          </Typography>
          <Grid item xs={12} sm={6} md={12 / 6} lg={2}>
            <Filters
              openFilter={openFilter}
              onOpenFilter={handleOpenFilter}
              onCloseFilter={handleCloseFilter}
              value={value}
              setValue={setValue}
              selectedMonth={selectedMonth}
              setSelectedMonth={setSelectedMonth}
            />
            <ExportButton setOpen={setOpen} />
          </Grid>
        </Box>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} md={12 / 4} lg={12 / 4}>
            <StatsCard
              title="Organic Lead"
              total="324"
              color="success"
              icon={
                <img alt="icon" src="/assets/icons/statsCard/organic_lead.png" />
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} md={12 / 4} lg={12 / 4}>
            <StatsCard
              title="Inorganic Lead"
              // total={"11"}
              total="67"
              icon={<img alt="icon" src="/assets/icons/statsCard/inorganic.png" />}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={12 / 4} lg={12 / 4}>
            <StatsCard
              title="Assigned Lead"
              total="145"
              icon={<img alt="icon" src="/assets/icons/statsCard/assign.png" />}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={12 / 4} lg={12 / 4}>
            <StatsCard
              title="Unassigned Lead"
              total={
                "24"
              }
              icon={<img alt="icon" src="/assets/icons/statsCard/unassign.png" />}
            />
          </Grid>
          <Grid item xs={12} md={3} lg={6}>
            <TaskBySite
              title="Overall Lead Status report"
              chartData={[
                { label: "Lost Lead", value: 8 },
                { label: "closed Lead", value: 92 },
              ]}
              chartColors={[
                theme.palette.primary.main,
                theme.palette.info.main,
                theme.palette.warning.main,
                theme.palette.error.main,
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={6}>
            <ColumnChart
              title={"Weekly Lead Status"}
              chartData={[
                {
                  name: "Closed",
                  data: [10, 9, 10, 11],
                },
                {
                  name: "Lost",
                  data: [4, 3, 2, 1],
                },
              ]}
              category={["W1", "W2", "W3", "W4"]}
            />
          </Grid>

          <Grid item xs={12} md={3} lg={6}>
            <GradientDonut
              title={"Lead Status"}
              chartData={[
                { label: "Organic", value: 100 },
                { label: "In oraganic", value: 40 },
              ]}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <FunnelChart
              title={"Probability wise Lead Status"}
              chartData={[
                {
                  name: "Funnel Series",
                  data: [0.2, 0.4, 0.5, 0.7, 0.8],
                },
              ]}
              category={[
                "Create Lead",
                "Analyse Requirement",
                "Identify Stakeholders",
                "Proposal & Demo",
                "Close",
              ]}
            />
          </Grid>

          {/* <Grid item xs={12} md={3} lg={3}>
            <TaskBySite
              title="Top 5 Busy Bots"
              chartData={[
                { label: "Expense Bot", value: 1000 },
                { label: "Login Bot", value: 3000 },
                { label: "Bot Manager", value: 4000 },
              ]}
              chartColors={[
                theme.palette.primary.main,
                theme.palette.info.main,
                theme.palette.warning.main,
                theme.palette.error.main,
              ]}
            />
          </Grid> */}
          {/* 
          <Grid item xs={12} md={6} lg={3}>
            <GaugeCharts title={"Bot Utilization %"} />
          </Grid> */}
          {/* 
          <Grid item xs={12} md={3} lg={3}>
            <TaskBySite
              title="Top 5 Consecutive Failed Bots"
              chartData={TOP_5_CONSECUTIVE_FAILED_BOTS[selectedMonth]}
              chartColors={[
                theme.palette.primary.main,
                theme.palette.info.main,
                theme.palette.warning.main,
                theme.palette.error.main,
              ]}
            />
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <TaskBySite
              title="Run ID Success Ratio %"
              chartData={RUN_ID_RATIO[selectedMonth]}
              chartColors={[
                theme.palette.primary.main,
                theme.palette.info.main,
                theme.palette.warning.main,
                theme.palette.error.main,
              ]}
            />
          </Grid> */}
        </Grid>
      </Container>
    </>
  );
};

export default DashboardAppPage;
