import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { NavLink as RouterLink, useLocation } from "react-router-dom";
import { Box, List, ListItemText } from "@mui/material";
import { StyledNavItem, StyledNavItemIcon } from "./styles";

NavSection.propTypes = {
  data: PropTypes.array,
};

export default function NavSection({ data = [], ...other }) {
  const [openSubMenu, setOpenSubMenu] = useState({});
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const parent = params.get("path")?.split("-")[0];
    if (params.has("path")) {
      setOpenSubMenu({ ...openSubMenu, [parent]: true });
    } else {
      setOpenSubMenu({});
    }
  }, [location.search]); //eslint-disable-line

  const handleParentClick = () => {
    setOpenSubMenu({});
  };

  return (
    <Box {...other}>
      <List disablePadding sx={{ p: 1 }}>
        {data.map((item) => (
          <React.Fragment key={item.title}>
            <NavItem
              item={item}
              handleParentClick={handleParentClick}
              openSubMenu={openSubMenu?.[item.name]}
            />
            {item.children && openSubMenu?.[item.name] && (
              <List disablePadding>
                {item.children.map((child) => (
                  <NavItem key={child.title} item={child} isChild />
                ))}
              </List>
            )}
          </React.Fragment>
        ))}
      </List>
    </Box>
  );
}

NavItem.propTypes = {
  item: PropTypes.object,
  isChild: PropTypes.bool,
  handleParentClick: PropTypes.func,
};

export function NavItem({ item, isChild, handleParentClick }) {
  const { title, path, icon, info } = item;
  const [currentPath, setCurrentPath] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    setCurrentPath(params.get("path"));
  }, [location.search]);

  const handlePath = (newPath) => {
    if (isChild) {
      const params = new URLSearchParams(location.search);
      params.set("path", newPath);
      const newQueryString = params.toString();
      const newLocation = `${location.pathname}${
        newQueryString ? `?${newQueryString}` : ""
      }`;
      return newLocation;
    }

    return newPath;
  };

  return (
    <StyledNavItem
      component={RouterLink}
      to={handlePath(path)}
      sx={{
        color: "#fafafa",
        "&.active": {
          color: "#3b92fe",
          bgcolor: "action.selected",
          fontWeight: "fontWeightBold",
          fontSize: "16px",
        },
        "&.child-active": {
          color: "#3b92fe !important",
          bgcolor: "action.selected",
          fontWeight: "fontWeightBold",
          fontSize: "16px",
        },
        "&.normal-child": {
          bgcolor: "transparent !important",
          color: "white !important",
        },
        ...(isChild && { pl: 3 }),
      }}
      className={
        isChild && (currentPath === path ? "child-active" : "normal-child")
      }
      onClick={handleParentClick}
    >
      <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>

      <ListItemText
        disableTypography
        primary={title}
        sx={{ fontSize: "16px" }}
      />

      {info && info}
    </StyledNavItem>
  );
}
