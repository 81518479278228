import { Box, Typography } from "@mui/material";
import classNames from "classnames";
import React from "react";
import NameProfilePic from "src/components/common/name-profile-pic";
import {
  Edit,
  EmailOutlined,
  LeaderboardOutlined,
  PhoneAndroidOutlined,
  Save,
  Language,
} from "@mui/icons-material";

const Header = ({
  styles,
  data,
  setIsSummaryEditing,
  isSummaryEditing,
  editedData,
  setEditedData,
  handleEdit,
  handleSave,
}) => {
  return (
    <Box className={classNames("common-box-shadow", styles["header-main-div"])}>
      <Box className={styles["account-details"]}>
        <Box className={styles["profile-info"]}>
          <NameProfilePic
            letter={data?.first_name ? data?.first_name[0].toUpperCase() : ""}
            customClass={styles["profile-icon"]}
            customStyle={{
              fontSize: "13px !important",
            }}
          />
          <Box>
            <Typography className={styles["account-name"]}>
              {data?.first_name} {data?.last_name}
              <Box className={styles["head_stacks"]}>
                <Box
                  className={classNames(
                    "info-chip",
                    styles["custom-chip"],
                    data?.is_active ? "" : "danger-chip"
                  )}
                >
                  {data?.is_active ? "Active" : "Inactive"}
                </Box>
              </Box>
            </Typography>
            <Box className={styles["short-info"]}>
              <Box className="in-box">
                <Box className={styles["head_stack"]}>
                  <Box className={styles["head_stacks2"]}>
                    <LeaderboardOutlined className={styles["org_icon"]} />
                    <input
                      value={
                        isSummaryEditing
                          ? editedData?.company
                          : data?.company || "----"
                      }
                      onChange={(e) =>
                        handleEdit("org", {
                          ...editedData?.company,
                          name: e.target.value,
                        })
                      }
                      readOnly
                      className={styles["editable_field"]}
                    />
                  </Box>
                  <Box
                    className={classNames(
                      styles["head_stacks2"],
                      isSummaryEditing ? styles["editable_field_border"] : ""
                    )}
                  >
                    <EmailOutlined className={styles["org_icon"]} />
                    <input
                      value={
                        isSummaryEditing
                          ? editedData?.email
                          : data?.email || "----"
                      }
                      name="email"
                      onChange={(e) => handleEdit("email", e.target.value)}
                      readOnly={!isSummaryEditing}
                      className={classNames(styles["editable_field"])}
                    />
                  </Box>
                </Box>
                <Box className={styles["head_stack"]}>
                  <Box
                    className={classNames(
                      styles["head_stacks2"],
                      isSummaryEditing ? styles["editable_field_border"] : ""
                    )}
                  >
                    <Language className={styles["org_icon"]} />
                    <input
                      value={
                        isSummaryEditing
                          ? editedData?.website
                          : data?.website || "----"
                      }
                      onChange={(e) => handleEdit("website", e.target.value)}
                      readOnly={!isSummaryEditing}
                      className={styles["editable_field"]}
                    />
                  </Box>
                  <Box
                    className={classNames(
                      styles["head_stacks2"],
                      isSummaryEditing ? styles["editable_field_border"] : ""
                    )}
                  >
                    <PhoneAndroidOutlined className={styles["org_icon"]} />
                    <input
                      value={
                        isSummaryEditing
                          ? editedData?.phone
                          : data?.phone || "----"
                      }
                      readOnly={!isSummaryEditing}
                      name="phone"
                      onChange={(e) => handleEdit("phone", e.target.value)}
                      className={styles["editable_field"]}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className={styles["owner-info"]}>
          {data?.assignee && (
            <>
              <Box className={styles["account-owner-profile"]}>
                <NameProfilePic
                  letter={
                    data?.assignee?.user?.fullname
                      ? data?.assignee?.user?.fullname[0].toUpperCase()
                      : ""
                  }
                  customClass={styles["profile-icon"]}
                  customStyle={{
                    fontSize: "11px !important",
                  }}
                />
                <Box className={styles["annual-revenue"]}>
                  <Typography className={styles["data"]}>
                    {data?.assignee?.user?.fullname || "----"}
                  </Typography>
                  <Typography className={styles["header"]}>Owner</Typography>
                </Box>
              </Box>
            </>
          )}
          <Box
            className={styles["border"]}
            sx={{ height: "42px !important" }}
          />

          <Box className={styles["right-box"]}>
          {/* <Box
            className={classNames(
              styles["convert_btn"],
              styles["lead-action-btn"]
            )}
            onClick={handleConvertOpen}
            color="primary"
            autoFocus
          >
            <PublishedWithChanges sx={{ height: "15px", width: "15px" }} />{" "}
            Convert
          </Box> */}
            {isSummaryEditing ? (
              <Box
                onClick={() => {
                  handleSave();
                  setIsSummaryEditing(false);
                }}
                className={classNames(
                  styles["convert_btn"],
                  styles["lead-action-btn"]
                )}
              >
                <Save sx={{ height: "15px", width: "15px" }} /> Save
              </Box>
            ) : (
              <Box
                onClick={() => {
                  setIsSummaryEditing(true);
                  setEditedData({
                    email: data?.email,
                    phone: data?.phone,
                    website: data?.website,
                    description: data?.description,
                    status: data?.status,
                  });
                }}
                className={classNames(
                  styles["convert_btn"],
                  styles["lead-action-btn"]
                )}
              >
                <Edit sx={{ height: "15px", width: "15px" }} /> Edit
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Header;
