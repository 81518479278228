import { Box, Typography } from "@mui/material";
import classNames from "classnames";
import React from "react";
import { CommonInputField } from "src/components/common/common-input-field";

const MoreInfo = ({ styles, data }) => {
  const singleInfo = (name, placeholder, value, minLines = 3) => {
    return (
      <>
        <Typography className={styles["more-info-placeholder"]}>{placeholder}</Typography>
        <Box className={styles["address-single-info"]}>
          <Box className={styles["more-info-input-box"]}>
            <CommonInputField
              name={name}
              value={value}
              handleChange={() => {}}
              placeholder={placeholder}
              customClass={styles["common-input-div"]}
              customInputClass={styles["common-input"]}
              readOnly={true}
              minLines={minLines}
            />
          </Box>
        </Box>
      </>
    );
  };

  return (
    <Box
      className={classNames("common-box-shadow", styles["account-info-card"])}
    >
      <Typography className={styles["heading"]}>More Info</Typography>
      {singleInfo(
        "account_name",
        "Account Name",
        "A. Datom Corporatiojjwjdblwebhfjhewldhewlhlkn"
      )}
      {singleInfo("alternate_phone", "Alternate Phone", data?.lead_detail?.phone)}
      {singleInfo("website", "Website", data?.lead_detail?.website)}
    </Box>
  );
};

export default MoreInfo;
