import React from "react";
import { Helmet } from "react-helmet-async";
import { styled } from "@mui/material/styles";
import { Box, Typography, Grid } from "@mui/material";
import { ToastContainer } from "react-toastify";

import Logo from "../../../components/logo";
import { useResetPassMutation } from "../../../hooks/useSessiondata";
import { showNotification } from "../../../components/notification";
import "react-toastify/dist/ReactToastify.css";
import { AuthForm } from "src/sections/auth/login";

const StyledRoot = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100vh",
  },
}));

const StyledContent = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  height: "100%",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(12, 0),
}));

const ReserPassword = () => {
  const resetMutation = useResetPassMutation();

  const handleReset = async (email) => {
    try {
      await resetMutation.mutateAsync(
        { email },
        {
          onSuccess: (data) => {
            setTimeout(() => {
              showNotification("success", "Please check your email.", 3000);
            }, 100);
          },
        }
      );
    } catch (error) {
      showNotification("error", error, 8000);
    }
  };
  return (
    <>
      <ToastContainer />
      {resetMutation.isLoading}
      <Helmet>
        <title> Reset Password | Lead Management System </title>
      </Helmet>

      <StyledRoot>
      <Box maxWidth="sm" >
          <StyledContent>
            <Grid
              sx={{
                display: "flex",
                justifyContent: "center",
                paddingBottom: "20px",
              
              }}
            >
              <Logo />
            </Grid>
            <Grid sx={{ textAlign: "center", marginBottom: "50px" }}>
              <Typography variant="h4" gutterBottom>
                Enter the email address to reset your password.
              </Typography>
            </Grid>
            
            <AuthForm type="Reset_Password" handleUser={handleReset} />
          </StyledContent>
        </Box>
      </StyledRoot>
    </>
  );
};

export default ReserPassword;
