import Button from "@mui/material/Button";
import { listClasses } from "@mui/material/List";
import Typography from "@mui/material/Typography";

export default function ExportButton(props) {
  const { setOpen } = props;

  const handleOpen = (event) => {
    setOpen(false);
    setTimeout(() => {
      window.print();
    }, 1000);
  };

  const styles = {
    mainButton: {
      backgroundColor: "#ffff",
      "& hover": { backgroundColor: "#919eab14" },
      boxShadow: "0 4px 6px rgba(108, 180, 241, 0.24)",
    },
    menu: {
      [`& .${listClasses.root}`]: {
        p: 0,
      },
    },
  };

  return (
    <Button
      disableRipple
      color="inherit"
      onClick={handleOpen}
      endIcon={
        <img
          alt="icon"
          src="/assets/icons/dashboard/arrow.svg"
          style={{ height: "30px" }}
        />
      }
      sx={styles?.mainButton}
    >
      Export
      <Typography
        component="span"
        variant="subtitle2"
        sx={{ color: "text.secondary" }}
      ></Typography>
    </Button>
  );
}
