import React from "react";
import ReactApexChart from "react-apexcharts";
import { Card, CardHeader, Typography } from "@mui/material";

const styles = {
  card: { height: "98%",},
  title: {
    fontSize: "14px",
    textAlign: "center",
    fontWeight: "bold",
    padding: "20px",
  },
  chart:{margin:"0px 10px"}
};

const FunnelChart = ({ title, chartData, category, name }) => {
  const options = {
    chart: {
      type: "bar",
      height: 240,
    },
    plotOptions: {
      bar: {
        borderRadius: 0,
        horizontal: true,
        barHeight: "80%",
        isFunnel: true,
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val, opt) {
        return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val;
      },
      dropShadow: {
        enabled: false,
      },
    },

    xaxis: {
      categories: category,
    },
    legend: {
      show: false,
    },
  };

  return (
    <Card sx={styles?.card}>
      <CardHeader
        title={
          <Typography
            sx={{ fontSize: "14px", textAlign: "center", fontWeight: "bold" }}
          >
            {title}
          </Typography>
        }
      />
      <div style={styles?.chart}>
        <ReactApexChart
          options={options}
          series={chartData}
          type="bar"
          height={240}
          width="100%"
        />
      </div>
      <div id="html-dist"></div>
    </Card>
  );
};

export default FunnelChart;
