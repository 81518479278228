import React from "react";
import { Box, Tab, Typography } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import Account from "./general";
import PersonIcon from '@mui/icons-material/Person';
import BusinessIcon from '@mui/icons-material/Business';
import SettingIcon from '@mui/icons-material/Settings';
import Organisation from "./organisation";
import Setting from "./setting";


const Profile = () => {
    const [value, setValue] = React.useState('General');
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <Box>
            <Typography sx={{ fontWeight: "bold", fontSize: "1.5rem", paddingBottom: "10px" }}>Profile</Typography>

            <TabContext value={value}>
                <Box sx={{ gap: "4rem", display: "flex" }}>
                    <TabList onChange={handleChange} aria-label="lab API tabs example">
                        <Tab sx={{ marginRight: "1rem", display: 'flex', alignItems: 'center' }} value="General" label={
                            <Box sx={{ display: 'flex', alignItems: 'center', fontWeight: '500' }}>
                                <PersonIcon sx={{ marginRight: '0.5rem' }} />
                                General
                            </Box>
                        } />
                        <Tab sx={{ marginRight: "1rem", display: 'flex', alignItems: 'center' }} value="Organization" label={
                            <Box sx={{ display: 'flex', alignItems: 'center', fontWeight: '500' }}>
                                <BusinessIcon sx={{ marginRight: '0.5rem' }} />
                                Organisation
                            </Box>
                        } />
                        <Tab sx={{ marginRight: "1rem", display: 'flex', alignItems: 'center' }} value="Setting" label={
                            <Box sx={{ display: 'flex', alignItems: 'center', fontWeight: '500' }}>
                                <SettingIcon sx={{ marginRight: '0.5rem' }} />
                                Settings
                            </Box>
                        } />
                    </TabList>
                </Box>
                <TabPanel value="General">
                    <Account />
                </TabPanel>
                <TabPanel value="Organization">
                    <Organisation />
                </TabPanel>
                <TabPanel value="Setting">
                   <Setting />
                </TabPanel>
            </TabContext>
        </Box>
    );
}

export default Profile;
