import { Box } from "@mui/material";
import React from "react";
import AccountInfo from "./account-info";
import Address from "./address";

const Summary = ({ styles,data }) => {
  return (
    <Box className={styles["summary-main"]}>
      <Box className={styles["first-col"]}>
        <AccountInfo styles={styles} data={data}/>
        <Box className={styles["vertical-spacer"]} />
        <Address styles={styles} data={data}/>
      </Box>
    </Box>
  );
};

export default Summary;
