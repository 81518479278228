import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";
import { Card, CardHeader, Typography } from "@mui/material"; 

const styles = {
    card: { height: "98%" },
    title: { fontSize: "14px", textAlign: "center", fontWeight: "bold" },
  };

const ColumnChart = ({title,chartData,category}) => {

  const options  = {
    chart: {
      type: "bar",
      height: 240,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: true,
    },
    yaxis: {
        tickAmount: 3,
        min: 0,
        max: 15
      },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: category
    },
    // yaxis: {
    //   title: {
    //     text: "$ (thousands)",
    //   },
    // },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return  val ;
        },
      },
    },
  };

  return (
    <Card sx={styles?.card}>
    <CardHeader
      title={
        <Typography
          sx={{ fontSize: "14px", textAlign: "center", fontWeight: "bold" }}
        >
          {title}
        </Typography>
      }
      sx={{paddingTop: "15px"}}
    
    />
      <div id="chart">
        <ReactApexChart
          options={options}
          series={chartData}
          type="bar"
          height={240}
        />
      </div>
      <div id="html-dist"></div>
   </Card>
  );
};

export default ColumnChart;
