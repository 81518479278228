import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import Summary from "./components/summary/summary";
import Notes from "./components/notes";
import Attachment from "./components/attachment";
import styles from "./style.module.css";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import {
  useDeleteLeadAttachmentMutation,
  useDeleteLeadNoteMutation,
  useLeadAttachmentMutation,
  useLeadDetails,
  useLeadNotesEditMutation,
  useLeadNotesMutation,
  useLeadToOpportunityMutation,
  useLeadUpdateMutation,
} from "src/hooks/useLeadsData";
import Loader from "src/components/common/loader";
import { useQueryClient } from "@tanstack/react-query";
import { showNotification } from "src/components/notification";
import ConvertDialog from "./components/convert-dialog";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import Header from "./components/header";
import { ActivityPage } from "./components/activity/index";

const DetailView = () => {
  const [selectedTab, setSelectedTab] = useState(0);

  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const { data, isLoading } = useLeadDetails({ id });
  const [value, setValue] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const [isSummaryEditing, setIsSummaryEditing] = useState(false);
  const [isOpen, setConvertOpen] = useState(false);
  const [conversionFormData, setConversionFormData] = useState({
    stage: "default",
  });
  const [notesFormOpen, setNotesFormOpen] = useState(false);
  const [editedData, setEditedData] = useState(data);
  const [currentPathValue, setCurrentPathValue] = useState(null);
  const [notesData, setNotesData] = useState({ editing: false });

  const attachmentMenuOpen = Boolean(anchorEl?.attachment);
  const headerMenuOpen = Boolean(anchorEl?.header);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const pathParam = params.get("path");
    setCurrentPathValue(pathParam);
  }, [location.search]);

  useEffect(() => {
    const removeStatusBar = () => {
      const statusBarElement = document.querySelector(".jodit-status-bar");
      if (statusBarElement) {
        statusBarElement.remove();
      } else {
        setTimeout(removeStatusBar, 100);
      }
    };

    removeStatusBar();
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(location.search);

    if (!params.get("path")) {
      navigate(`${location.pathname}?path=lead-summary`);
    }
  }, [location.search]); // eslint-disable-line

  const editNotesMutation = useLeadNotesEditMutation();
  const addNotesMutation = useLeadNotesMutation();
  const leadsMutation = useLeadAttachmentMutation();
  const deleteLeadAttachmentMutation = useDeleteLeadAttachmentMutation();
  const deleteLeadNoteMutation = useDeleteLeadNoteMutation();
  const leadsUpdateMutation = useLeadUpdateMutation();
  const leadsToOpportunityMutation = useLeadToOpportunityMutation();
  const queryClient = useQueryClient();

  const getValue = (value) => {
    setValue(value);
  };

  const handleOpenMenu = (event, tab) => {
    if (tab === "attachment") {
      setAnchorEl({ ...anchorEl, attachment: event.currentTarget });
    }
  };

  const handleCloseMenu = (tab) => {
    if (tab === "attachment") {
      setAnchorEl({ ...anchorEl, attachment: null });
    }
    setAnchorEl(null);
  };

  useEffect(() => {
    if (data) setValue(data?.description || "");
    setEditedData(data);
  }, [data]);

  const handleNotes = async (e) => {
    e.preventDefault();
    if (notesData?.editing) {
      try {
        await editNotesMutation.mutateAsync(
          {
            lead_id: id,
            notes_id: notesData?.notes_id,
            title: notesData?.message?.title,
            description: notesData?.message?.description,
          },
          {
            onSuccess: (data) => {
              queryClient.invalidateQueries({ queryKey: ["leadDetail"] });
              showNotification("success", "Notes Updated Successfully.!", 5000);
              setNotesFormOpen(false);
              setNotesData({ editing: false });
            },
          }
        );
      } catch (error) {
        showNotification("error", error, 8000);
      }
    } else {
      try {
        await addNotesMutation.mutateAsync(
          {
            title: notesData?.message?.title,
            description: notesData?.message?.description,
            lead_id: id,
          },
          {
            onSuccess: (data) => {
              queryClient.invalidateQueries({ queryKey: ["leadDetail"] });
              showNotification("success", "Notes Added Successfully.!", 2000);
              setNotesFormOpen(false);
              setNotesData({ editing: false });
            },
          }
        );
      } catch (error) {
        showNotification("error", error, 8000);
      }
    }
  };

  const handleEdit = (field, value) => {
    setEditedData({ ...editedData, [field]: value });
  };

  const handleFileUpload = async (file) => {
    var formData = new FormData();
    formData.append("document_file", file);
    formData.append("title", "Title");
    formData.append("description", "Description");
    try {
      await leadsMutation.mutateAsync(
        { data: formData, id: id },
        {
          onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: ["leadDetail"] });
            showNotification("success", "Attachment Added Successfully", 8000);
          },
        }
      );
    } catch (error) {
      showNotification("error", error, 8000);
    }
  };

  const handleDeleteAttachment = async (e) => {
    e.preventDefault();
    try {
      await deleteLeadAttachmentMutation.mutateAsync(
        { lead_id: id, attachment_id: deleteId },
        {
          onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: ["leadDetail"] });
            showNotification(
              "success",
              "Attachment Deleted Successfully.!",
              5000
            );
          },
        }
      );
    } catch (error) {
      showNotification("error", error, 8000);
    }
    setDeleteId(null);
  };

  const handleDeleteNotes = async (e, notes_id) => {
    e.preventDefault();
    try {
      await deleteLeadNoteMutation.mutateAsync(
        { lead_id: id, note_id: notes_id },
        {
          onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: ["leadDetail"] });
            showNotification("success", "Notes Deleted Successfully.!", 5000);
          },
        }
      );
    } catch (error) {
      showNotification("error", error, 8000);
    }
    setDeleteId(null);
  };

  const handleSummarySave = async () => {
    try {
      await leadsUpdateMutation.mutateAsync(
        { ...editedData, description: value, id: id },
        {
          onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: ["leadDetail"] });
            showNotification("success", "Summary Updated Successfully.!", 5000);
          },
        }
      );
    } catch (error) {
      showNotification("error", error, 8000);
    }
    setIsSummaryEditing(false);
  };

  const handleOpportunityConversion = async (e) => {
    e.preventDefault();
    try {
      await leadsToOpportunityMutation.mutateAsync(
        { ...conversionFormData, lead: id },
        {
          onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: ["leadDetail"] });
            showNotification(
              "success",
              "Lead Converted to Opportunity..!",
              5000
            );
            setConvertOpen(false);
          },
        }
      );
    } catch (error) {
      showNotification("error", error, 8000);
    }
  };

  const handleConversionFormDataChange = (e) => {
    e.preventDefault();
    setConversionFormData({
      ...conversionFormData,
      [e.target.name]: e.target.value,
    });
  };

  return isLoading ? (
    <Loader />
  ) : (
    <Box>
      <Header
        styles={styles}
        activeTab={selectedTab}
        setActiveTab={setSelectedTab}
        data={data}
        setIsSummaryEditing={setIsSummaryEditing}
        isSummaryEditing={isSummaryEditing}
        editedData={editedData}
        handleEdit={handleEdit}
        setEditedData={setEditedData}
        handleSave={handleSummarySave}
        handleConvertOpen={() => setConvertOpen(true)}
        anchorEl={anchorEl?.header || null}
        handleOpenMenu={handleOpenMenu}
        handleCloseMenu={handleCloseMenu}
        open={headerMenuOpen}
      />

      {currentPathValue === "lead-summary" && (
        <Summary
          styles={styles}
          data={data}
          getValue={getValue}
          value={value}
          isSummaryEditing={isSummaryEditing}
          editedData={editedData}
          handleChange={handleEdit}
        />
      )}
      {currentPathValue === "lead-notes" && (
        <Notes
          styles={styles}
          data={data}
          handleDeleteNotes={handleDeleteNotes}
          notesData={notesData}
          setNotesData={setNotesData}
          handleSubmit={handleNotes}
          notesFormOpen={notesFormOpen}
          setNotesFormOpen={setNotesFormOpen}
        />
      )}
      {currentPathValue === "lead-attachment" && (
        <Attachment
          styles={styles}
          data={data}
          handleFileUpload={handleFileUpload}
          anchorEl={anchorEl?.attachment || null}
          handleOpenMenu={handleOpenMenu}
          handleCloseMenu={handleCloseMenu}
          open={attachmentMenuOpen}
          handleDeleteAttachment={handleDeleteAttachment}
          deleteId={deleteId}
          setDeleteId={setDeleteId}
        />
      )}
      {currentPathValue === "lead-activity" && (
        <ActivityPage />
        // <Activity
        //   data={data}
        //   taskFormData={taskFormData}
        //   setTaskFormData={setTaskFormData}
        //   meetingFormData={meetingFormData}
        //   setMeetingFormData={setMeetingFormData}
        //   activityModalOpen={activityModalOpen}
        //   setActivityModalOpen={setActivityModalOpen}
        //   isActivityEditing={isActivityEditing}
        //   setIsActivityEditing={setIsActivityEditing}
        // />
      )}

      <ConvertDialog
        isOpen={isOpen}
        data={data}
        conversionFormData={conversionFormData}
        handleChange={handleConversionFormDataChange}
        openDialog={() => setConvertOpen(true)}
        closeDialog={() => setConvertOpen(false)}
        handleSubmit={handleOpportunityConversion}
      />
      <ToastContainer />
    </Box>
  );
};

export default DetailView;
