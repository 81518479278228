export const SUCCESS_FAILURE = {
  jan: [
    { label: "Failure", value: 23 },
    { label: "Success", value: 77 },
  ],
  feb: [
    { label: "Failure", value: 22 },
    { label: "Success", value: 78 },
  ],
  mar: [
    { label: "Failure", value: 79 },
    { label: "Success", value: 21 },
  ],
  apr: [
    { label: "Failure", value: 67 },
    { label: "Success", value: 33 },
  ],
  may: [
    { label: "Failure", value: 78 },
    { label: "Success", value: 34 },
  ],
  jun: [
    { label: "Failure", value: 55 },
    { label: "Success", value: 45 },
  ],
  jul: [
    { label: "Failure", value: 43 },
    { label: "Success", value: 57 },
  ],
  aug: [
    { label: "Failure", value: 23 },
    { label: "Success", value: 77 },
  ],
  sep: [
    { label: "Failure", value: 75 },
    { label: "Success", value: 25 },
  ],
  oct: [
    { label: "Failure", value: 12 },
    { label: "Success", value: 88 },
  ],
  nov: [
    { label: "Failure", value: 89 },
    { label: "Success", value: 11 },
  ],
  dec: [
    { label: "Failure", value: 69 },
    { label: "Success", value: 31 },
  ],
};

export const BOT_UTILIZATION = {
  jan: [
    { x: "Expense Ratio", y: 17 },
    { x: "Prod QST Fund", y: 5 },
    { x: "Derivative", y: 3 },
    { x: "MF Price", y: 3 },
    { x: "Prod QST MF Pr", y: 2 },
    { x: "Equity", y: 1 },
    { x: "Fund Account", y: 1 },
  ],
  feb: [
    { x: "Expense Ratio", y: 17 },
    { x: "Prod QST Fund", y: 5 },
    { x: "Derivative", y: 3 },
    { x: "MF Price", y: 3 },
    { x: "Prod QST MF Pr", y: 2 },
    { x: "Equity", y: 1 },
    { x: "Fund Account", y: 1 },
  ],
  mar: [
    { x: "Expense Ratio", y: 17 },
    { x: "Prod QST Fund", y: 5 },
    { x: "Derivative", y: 3 },
    { x: "MF Price", y: 3 },
    { x: "Prod QST MF Pr", y: 2 },
    { x: "Equity", y: 1 },
    { x: "Fund Account", y: 1 },
  ],
  apr: [
    { x: "Expense Ratio", y: 17 },
    { x: "Prod QST Fund", y: 5 },
    { x: "Derivative", y: 3 },
    { x: "MF Price", y: 3 },
    { x: "Prod QST MF Pr", y: 2 },
    { x: "Equity", y: 1 },
    { x: "Fund Account", y: 1 },
  ],
  may: [
    { x: "Expense Ratio", y: 17 },
    { x: "Prod QST Fund", y: 5 },
    { x: "Derivative", y: 3 },
    { x: "MF Price", y: 3 },
    { x: "Prod QST MF Pr", y: 2 },
    { x: "Equity", y: 1 },
    { x: "Fund Account", y: 1 },
  ],
  jun: [
    { x: "Expense Ratio", y: 17 },
    { x: "Prod QST Fund", y: 5 },
    { x: "Derivative", y: 3 },
    { x: "MF Price", y: 3 },
    { x: "Prod QST MF Pr", y: 2 },
    { x: "Equity", y: 1 },
    { x: "Fund Account", y: 1 },
  ],
  jul: [
    { x: "Expense Ratio", y: 17 },
    { x: "Prod QST Fund", y: 5 },
    { x: "Derivative", y: 3 },
    { x: "MF Price", y: 3 },
    { x: "Prod QST MF Pr", y: 2 },
    { x: "Equity", y: 1 },
    { x: "Fund Account", y: 1 },
  ],
  aug: [
    { x: "Expense Ratio", y: 17 },
    { x: "Prod QST Fund", y: 5 },
    { x: "Derivative", y: 3 },
    { x: "MF Price", y: 3 },
    { x: "Prod QST MF Pr", y: 2 },
    { x: "Equity", y: 1 },
    { x: "Fund Account", y: 1 },
  ],
  sep: [
    { x: "Expense Ratio", y: 17 },
    { x: "Prod QST Fund", y: 5 },
    { x: "Derivative", y: 3 },
    { x: "MF Price", y: 3 },
    { x: "Prod QST MF Pr", y: 2 },
    { x: "Equity", y: 1 },
    { x: "Fund Account", y: 1 },
  ],
  oct: [
    { x: "Expense Ratio", y: 17 },
    { x: "Prod QST Fund", y: 5 },
    { x: "Derivative", y: 3 },
    { x: "MF Price", y: 3 },
    { x: "Prod QST MF Pr", y: 2 },
    { x: "Equity", y: 1 },
    { x: "Fund Account", y: 1 },
  ],
  nov: [
    { x: "Expense Ratio", y: 17 },
    { x: "Prod QST Fund", y: 5 },
    { x: "Derivative", y: 3 },
    { x: "MF Price", y: 3 },
    { x: "Prod QST MF Pr", y: 2 },
    { x: "Equity", y: 1 },
    { x: "Fund Account", y: 1 },
  ],
  dec: [
    { x: "Expense Ratio", y: 17 },
    { x: "Prod QST Fund", y: 5 },
    { x: "Derivative", y: 3 },
    { x: "MF Price", y: 3 },
    { x: "Prod QST MF Pr", y: 2 },
    { x: "Equity", y: 1 },
    { x: "Fund Account", y: 1 },
  ],
};

export const RUN_ID_RATIO = {
  jan: [
    { label: "process 1", value: 30 },
    { label: "process 2", value: 5 },
    { label: "process 3", value: 10 },
    { label: "process 4", value: 10 },
  ],
  feb: [
    { label: "process 1", value: 67 },
    { label: "process 2", value: 5 },
    { label: "process 3", value: 23 },
    { label: "process 4", value: 45 },
  ],
  mar: [
    { label: "process 1", value: 30 },
    { label: "process 2", value: 5 },
    { label: "process 3", value: 67 },
    { label: "process 4", value: 34 },
  ],
  apr: [
    { label: "process 1", value: 30 },
    { label: "process 2", value: 5 },
    { label: "process 3", value: 23 },
    { label: "process 4", value: 90 },
  ],
  may: [
    { label: "process 1", value: 30 },
    { label: "process 2", value: 5 },
    { label: "process 3", value: 40 },
    { label: "process 4", value: 70 },
  ],
  jun: [
    { label: "process 1", value: 90 },
    { label: "process 2", value: 5 },
    { label: "process 3", value: 34 },
    { label: "process 4", value: 56 },
  ],
  jul: [
    { label: "process 1", value: 30 },
    { label: "process 2", value: 5 },
    { label: "process 3", value: 10 },
    { label: "process 4", value: 10 },
  ],
  aug: [
    { label: "process 1", value: 67 },
    { label: "process 2", value: 50 },
    { label: "process 3", value: 89 },
    { label: "process 4", value: 12 },
  ],
  sep: [
    { label: "process 1", value: 30 },
    { label: "process 2", value: 23 },
    { label: "process 3", value: 10 },
    { label: "process 4", value: 43 },
  ],
  oct: [
    { label: "process 1", value: 30 },
    { label: "process 2", value: 50 },
    { label: "process 3", value: 30 },
    { label: "process 4", value: 40 },
  ],
  nov: [
    { label: "process 1", value: 80 },
    { label: "process 2", value: 35 },
    { label: "process 3", value: 15 },
    { label: "process 4", value: 10 },
  ],
  dec: [
    { label: "process 1", value: 23 },
    { label: "process 2", value: 90 },
    { label: "process 3", value: 50 },
    { label: "process 4", value: 20 },
  ],
};

export const TOP_5_CONSECUTIVE_FAILED_BOTS = {
  jan: [
    { label: "Expense Ratio", value: 12 },
    { label: "Equity", value: 4 },
    { label: "MF Price", value: 11 },
  ],
  feb: [
    { label: "Expense Ratio", value: 3 },
    { label: "Equity", value: 4 },
    { label: "MF Price", value: 11 },
  ],
  mar: [
    { label: "Expense Ratio", value: 13 },
    { label: "Equity", value: 4 },
    { label: "MF Price", value: 31 },
  ],
  apr: [
    { label: "Expense Ratio", value: 13 },
    { label: "Equity", value: 40 },
    { label: "MF Price", value: 19 },
  ],
  may: [
    { label: "Expense Ratio", value: 32 },
    { label: "Equity", value: 14 },
    { label: "MF Price", value: 18 },
  ],
  jun: [
    { label: "Expense Ratio", value: 23 },
    { label: "Equity", value: 34 },
    { label: "MF Price", value: 41 },
  ],
  jul: [
    { label: "Expense Ratio", value: 43 },
    { label: "Equity", value: 44 },
    { label: "MF Price", value: 12 },
  ],
  aug: [
    { label: "Expense Ratio", value: 31 },
    { label: "Equity", value: 14 },
    { label: "MF Price", value: 56 },
  ],
  sep: [
    { label: "Expense Ratio", value: 31 },
    { label: "Equity", value: 14 },
    { label: "MF Price", value: 41 },
  ],
  oct: [
    { label: "Expense Ratio", value: 13 },
    { label: "Equity", value: 42 },
    { label: "MF Price", value: 41 },
  ],
  nov: [
    { label: "Expense Ratio", value: 63 },
    { label: "Equity", value: 43 },
    { label: "MF Price", value: 91 },
  ],
  dec: [
    { label: "Expense Ratio", value: 34 },
    { label: "Equity", value: 4 },
    { label: "MF Price", value: 51 },
  ],
};

export const STATS_CARD_DATA = {
  jan: {
    total_process: {
      value: "24",
    },
    successful_process: {
      value: "11",
    },
    failed_process: {
      value: "11",
    },
    total_execution_time: {
      value: "24:33:30",
    },
    bot_idle_time: {
      value: "215.44",
    },
  },
  feb: {
    total_process: {
      value: "34",
    },
    successful_process: {
      value: "23",
    },
    failed_process: {
      value: "23",
    },
    total_execution_time: {
      value: "24:56:30",
    },
    bot_idle_time: {
      value: "215.12",
    },
  },
  mar: {
    total_process: {
      value: "45",
    },
    successful_process: {
      value: "12",
    },
    failed_process: {
      value: "11",
    },
    total_execution_time: {
      value: "34:33:30",
    },
    bot_idle_time: {
      value: "123.44",
    },
  },
  apr: {
    total_process: {
      value: "67",
    },
    successful_process: {
      value: "23",
    },
    failed_process: {
      value: "34",
    },
    total_execution_time: {
      value: "24:12:12",
    },
    bot_idle_time: {
      value: "215.12",
    },
  },
  may: {
    total_process: {
      value: "56",
    },
    successful_process: {
      value: "34",
    },
    failed_process: {
      value: "90",
    },
    total_execution_time: {
      value: "24:90:30",
    },
    bot_idle_time: {
      value: "215.34",
    },
  },
  jun: {
    total_process: {
      value: "90",
    },
    successful_process: {
      value: "11",
    },
    failed_process: {
      value: "89",
    },
    total_execution_time: {
      value: "24:34:89",
    },
    bot_idle_time: {
      value: "215.12",
    },
  },
  jul: {
    total_process: {
      value: "12",
    },
    successful_process: {
      value: "90",
    },
    failed_process: {
      value: "56",
    },
    total_execution_time: {
      value: "24:34:90",
    },
    bot_idle_time: {
      value: "890.44",
    },
  },
  aug: {
    total_process: {
      value: "24",
    },
    successful_process: {
      value: "11",
    },
    failed_process: {
      value: "11",
    },
    total_execution_time: {
      value: "24:33:30",
    },
    bot_idle_time: {
      value: "215.44",
    },
  },
  sep: {
    total_process: {
      value: "89",
    },
    successful_process: {
      value: "67",
    },
    failed_process: {
      value: "67",
    },
    total_execution_time: {
      value: "56:33:30",
    },
    bot_idle_time: {
      value: "356.44",
    },
  },
  oct: {
    total_process: {
      value: "24",
    },
    successful_process: {
      value: "11",
    },
    failed_process: {
      value: "11",
    },
    total_execution_time: {
      value: "24:33:30",
    },
    bot_idle_time: {
      value: "215.44",
    },
  },
  nov: {
    total_process: {
      value: "24",
    },
    successful_process: {
      value: "89",
    },
    failed_process: {
      value: "56",
    },
    total_execution_time: {
      value: "23:23:23",
    },
    bot_idle_time: {
      value: "456.44",
    },
  },
  dec: {
    total_process: {
      value: "80",
    },
    successful_process: {
      value: "98",
    },
    failed_process: {
      value: "67",
    },
    total_execution_time: {
      value: "70:33:30",
    },
    bot_idle_time: {
      value: "678.44",
    },
  },
};

export const SCHEDULER_TABLE_ROWS = [
  {
    id: 1,
    sr_no: 40917,
    bot_start_time: "11/1/2023 0:00:00",
    start_time: "11/1/2023 0:00:00",
    bot_end_time: "11/1/2023 0:14:00",
    end_time: "11/1/2023 0:14:00",
    execution_time: "0:14:00",
    process_name: "Caliber_Process",
    task_name: "Closing_Price_of_MF",
    application_name: "Caliber_Application",
    login_user_id: "rpa.consultant@360.one",
    status: "Successful",
    description: "Bot Completed the Execution",
    run_id: 1,
    process_id: 1112023001,
  },
  {
    id: 2,
    sr_no: 40918,
    bot_start_time: "11/1/2023 0:20:00",
    start_time: "11/1/2023 0:20:00",
    bot_end_time: "11/1/2023 0:28:00",
    end_time: "11/1/2023 0:28:00",
    execution_time: "0:08:00",
    process_name: "Quantis_Process",
    task_name: "QTS-Equity_Price_Upload-BSE",
    application_name: "Quantis_Application",
    login_user_id: "BOT_AUTO",
    status: "Successful",
    description: "BSE File Uploaded Successfully in Quantis Application",
    run_id: 1,
    process_id: 1112023002,
  },
  {
    id: 3,
    sr_no: 40919,
    bot_start_time: "11/1/2023 0:30:00",
    start_time: "11/1/2023 0:30:00",
    bot_end_time: "11/1/2023 0:40:00",
    end_time: "11/1/2023 0:40:00",
    execution_time: "0:10:00",
    process_name: "Quantis_Process",
    task_name: "QTS-Equity_Price_Upload-NSE",
    application_name: "Quantis_Application",
    login_user_id: "BOT_AUTO",
    status: "Successful",
    description: "NSE File Uploaded Successfully in Quantis Application",
    run_id: 1,
    process_id: 1112023003,
  },
  {
    id: 4,
    sr_no: 40920,
    bot_start_time: "11/1/2023 0:42:00",
    start_time: "11/1/2023 0:42:00",
    bot_end_time: "11/1/2023 0:50:00",
    end_time: "11/1/2023 0:50:00",
    execution_time: "0:08:00",
    process_name: "Caliber_Process",
    task_name: "EOD_Caliber_Group2_Group3",
    application_name: "Caliber_Application",
    login_user_id: "rpa.consultant@360.one",
    status: "Successful",
    description: "Bot Execution Finished Sucessfully",
    run_id: 1,
    process_id: 1112023004,
  },
  {
    id: 5,
    sr_no: 40921,
    bot_start_time: "11/1/2023 0:52:00",
    start_time: "11/1/2023 0:52:00",
    bot_end_time: "11/1/2023 1:00:00",
    end_time: "11/1/2023 1:00:00",
    execution_time: "0:08:00",
    process_name: "Quantis_Process",
    task_name: "QTS-MF_Price",
    application_name: "Quantis_Application",
    login_user_id: "BOT_AUTO",
    status: "Successful",
    description: "File Uploaded Successfully on Quantis Application",
    run_id: 1,
    process_id: 1112023005,
  },
  {
    id: 6,
    sr_no: 40922,
    bot_start_time: "11/1/2023 1:02:00",
    start_time: "11/1/2023 1:02:00",
    bot_end_time: "11/1/2023 1:10:00",
    end_time: "11/1/2023 1:10:00",
    execution_time: "0:08:00",
    process_name: "Quantis_Process",
    task_name: "QTS-Debt_Price_Upload",
    application_name: "Quantis_Application",
    login_user_id: "BOT_AUTO",
    status: "Successful",
    description: "Debt Price Upload file Successfully in Quantis Application",
    run_id: 1,
    process_id: 1112023006,
  },
  {
    id: 7,
    sr_no: 40923,
    bot_start_time: "11/1/2023 1:12:00",
    start_time: "11/1/2023 1:12:00",
    bot_end_time: "11/1/2023 1:20:00",
    end_time: "11/1/2023 1:20:00",
    execution_time: "0:08:00",
    process_name: "Quantis_Process",
    task_name: "QTS-Expense_Ratio_Data_Upload",
    application_name: "Quantis_Application",
    login_user_id: "Bot_Auto",
    status: "Successful",
    description: "File Uploaded Successfully on Website",
    run_id: 1,
    process_id: 1112023007,
  },
  {
    id: 8,
    sr_no: 40924,
    bot_start_time: "11/1/2023 1:22:00",
    start_time: "11/1/2023 1:22:00",
    bot_end_time: "11/1/2023 1:36:00",
    end_time: "11/1/2023 1:36:00",
    execution_time: "0:14:00",
    process_name: "Quantis_Process",
    task_name: "QTS-Fund_Account_Holding-BackOffice",
    application_name: "Quantis_Application",
    login_user_id: "BOT_AUTO",
    status: "Successful",
    description: "File Uploaded Successfully on Quantis Application",
    run_id: 1,
    process_id: 1112023008,
  },
  {
    id: 9,
    sr_no: 40925,
    bot_start_time: "11/1/2023 1:38:00",
    start_time: "11/1/2023 1:38:00",
    bot_end_time: "11/1/2023 1:42:00",
    end_time: "11/1/2023 1:42:00",
    execution_time: "0:04:00",
    process_name: "Quantis_Process",
    task_name: "QTS-NAV_Upload",
    application_name: "Quantis_Application",
    login_user_id: "BOT_AUTO",
    status: "Successful",
    description: "File Uploaded Successfully on Quantis Application",
    run_id: 1,
    process_id: 1112023009,
  },
  {
    id: 10,
    sr_no: 40926,
    bot_start_time: "11/1/2023 2:01:00",
    start_time: "11/1/2023 2:01:00",
    bot_end_time: "11/1/2023 2:08:00",
    end_time: "11/1/2023 2:08:00",
    execution_time: "0:07:00",
    process_name: "IIFL-One_Process",
    task_name: "Holding_Reconciliation_AAA",
    application_name: "IIFL-One_Application",
    login_user_id: "corporate1",
    status: "Successful",
    description: "Bot Execute Successfully",
    run_id: 1,
    process_id: 1112023010,
  },
  {
    id: 11,
    sr_no: 40927,
    bot_start_time: "11/1/2023 2:09:00",
    start_time: "11/1/2023 2:09:00",
    bot_end_time: "11/1/2023 2:18:00",
    end_time: "11/1/2023 2:18:00",
    execution_time: "0:09:00",
    process_name: "IIFL-One_Process",
    task_name: "Holding_Reconciliation_AMC",
    application_name: "IIFL-One_Application",
    login_user_id: "corporate1",
    status: "Successful",
    description: "Bot Executed Successfully",
    run_id: 1,
    process_id: 1112023011,
  },
  {
    id: 12,
    sr_no: 40928,
    bot_start_time: "11/1/2023 3:16:00",
    start_time: "11/1/2023 3:16:00",
    bot_end_time: "11/1/2023 3:25:00",
    end_time: "11/1/2023 3:25:00",
    execution_time: "0:09:00",
    process_name: "Quantis_Process",
    task_name: "QTS-AUM_Process",
    application_name: "Quantis_Application",
    login_user_id: "BOT_AUTO",
    status: "Successful",
    description: "AUM file Successfully in Quantis Application",
    run_id: 1,
    process_id: 1112023012,
  },
  {
    id: 13,
    sr_no: 40929,
    bot_start_time: "11/1/2023 3:31:00",
    start_time: "11/1/2023 3:31:00",
    bot_end_time: "11/1/2023 3:46:00",
    end_time: "11/1/2023 3:46:00",
    execution_time: "0:15:00",
    process_name: "Quantis_Process",
    task_name: "QTS-Saleable_Upload",
    application_name: "Quantis_Application",
    login_user_id: "BOT_AUTO",
    status: "Successful",
    description: "File Uploaded Successfully on Quantis Application",
    run_id: 1,
    process_id: 1112023013,
  },
  {
    id: 14,
    sr_no: 40930,
    bot_start_time: "11/1/2023 4:03:00",
    start_time: "11/1/2023 4:03:00",
    bot_end_time: "11/1/2023 4:05:00",
    end_time: "11/1/2023 4:05:00",
    execution_time: "0:02:00",
    process_name: "IIFL-One_Process",
    task_name: "BOD_Process",
    application_name: "IIFL-One_Application",
    login_user_id: "corporate1",
    status: "Failed",
    description:
      "WS_AMC Login Failed. Invalid Creadentials or Capture Activity Failed.",
    run_id: 1,
    process_id: 1112023014,
  },
  {
    id: 15,
    sr_no: 40931,
    bot_start_time: "11/1/2023 7:12:00",
    start_time: "11/1/2023 7:12:00",
    bot_end_time: "11/1/2023 7:25:00",
    end_time: "11/1/2023 7:25:00",
    execution_time: "0:13:00",
    process_name: "Caliber_Process",
    task_name: "Commodity_Master_View-NCDEX",
    application_name: "Caliber_Application",
    login_user_id: "rpa.consultant@360.one",
    status: "Successful",
    description: "Confirmation Process Successfully Executed",
    run_id: 1,
    process_id: 1112023015,
  },
  {
    id: 16,
    sr_no: 40932,
    bot_start_time: "11/1/2023 7:20:00",
    start_time: "11/1/2023 7:20:00",
    bot_end_time: "11/1/2023 7:34:00",
    end_time: "11/1/2023 7:34:00",
    execution_time: "0:14:00",
    process_name: "Caliber_Process",
    task_name: "Closing_Price_of_MF",
    application_name: "Caliber_Application",
    login_user_id: "rpa.consultant@360.one",
    status: "Successful",
    description: "Bot Completed the Execution",
    run_id: 2,
    process_id: 1112023016,
  },
  {
    id: 17,
    sr_no: 40933,
    bot_start_time: "11/1/2023 7:36:00",
    start_time: "11/1/2023 7:36:00",
    bot_end_time: "11/1/2023 7:40:00",
    end_time: "11/1/2023 7:40:00",
    execution_time: "0:04:00",
    process_name: "Caliber_Process",
    task_name: "Fetch_Broker_Ledger_Txn_View-Wealth_Broker",
    application_name: "Caliber_Application",
    login_user_id: "rpa.consultant@360.one",
    status: "Failed",
    description:
      "Capture Element Acivity Failed or Server Downtime. Bot unable perform required action.",
    run_id: 1,
    process_id: 1112023017,
  },
  {
    id: 18,
    sr_no: 40934,
    bot_start_time: "11/1/2023 7:43:00",
    start_time: "11/1/2023 7:43:00",
    bot_end_time: "11/1/2023 7:49:00",
    end_time: "11/1/2023 7:49:00",
    execution_time: "0:06:00",
    process_name: "Caliber_Process",
    task_name: "Fetch_Broker_Ledger_Txn_View-IIL_Broker",
    application_name: "Caliber_Application",
    login_user_id: "rpa.consultant@360.one",
    status: "Successful",
    description: "Bot Uploaded file successfully on Caliber Website",
    run_id: 1,
    process_id: 1112023018,
  },
  {
    id: 19,
    sr_no: 40935,
    bot_start_time: "11/1/2023 7:57:00",
    start_time: "11/1/2023 7:57:00",
    bot_end_time: "11/1/2023 8:07:00",
    end_time: "11/1/2023 8:07:00",
    execution_time: "0:10:00",
    process_name: "Caliber_Process",
    task_name: "Commodity_Master_View-MCX",
    application_name: "Caliber_Application",
    login_user_id: "rpa.consultant@360.one",
    status: "Successful",
    description: "Confirmation Process Successfully Executed",
    run_id: 1,
    process_id: 1112023019,
  },
  {
    id: 20,
    sr_no: 40936,
    bot_start_time: "11/1/2023 8:05:00",
    start_time: "11/1/2023 8:05:00",
    bot_end_time: "11/1/2023 8:16:00",
    end_time: "11/1/2023 8:16:00",
    execution_time: "0:11:00",
    process_name: "Class_Process",
    task_name: "Margin_Request_Pledge",
    application_name: "Class_Application",
    login_user_id: "Wealth_UAT",
    status: "Failed",
    description: "Bot Failed While Login into Class Portal",
    run_id: 1,
    process_id: 1112023020,
  },
  {
    id: 21,
    sr_no: 40937,
    bot_start_time: "11/1/2023 8:10:00",
    start_time: "11/1/2023 8:10:00",
    bot_end_time: "11/1/2023 8:15:00",
    end_time: "11/1/2023 8:15:00",
    execution_time: "0:05:00",
    process_name: "Caliber_Process",
    task_name: "M2M_Commodity",
    application_name: "Caliber_Application",
    login_user_id: "rpa.consultant@360.one",
    status: "Successful",
    description: "Confirmation Process Successfully Executed",
    run_id: 1,
    process_id: 1112023021,
  },
  {
    id: 22,
    sr_no: 40938,
    bot_start_time: "11/1/2023 8:25:00",
    start_time: "11/1/2023 8:25:00",
    bot_end_time: "11/1/2023 8:38:00",
    end_time: "11/1/2023 8:38:00",
    execution_time: "0:13:00",
    process_name: "Class_Process",
    task_name: "Margin_Request_Pledge",
    application_name: "Class_Application",
    login_user_id: "Wealth_UAT",
    status: "Successful",
    description: "Bot executed successfully.",
    run_id: 2,
    process_id: 1112023022,
  },
  {
    id: 23,
    sr_no: 40939,
    bot_start_time: "11/1/2023 8:31:00",
    start_time: "11/1/2023 8:31:00",
    bot_end_time: "11/1/2023 8:37:00",
    end_time: "11/1/2023 8:37:00",
    execution_time: "0:06:00",
    process_name: "Caliber_Process",
    task_name: "TDS_Corpus_Settlement",
    application_name: "Caliber_Application",
    login_user_id: "rpa.consultant@360.one",
    status: "Successful",
    description: "Confirmation Process Successfully Executed",
    run_id: 1,
    process_id: 1112023023,
  },
  {
    id: 24,
    sr_no: 40940,
    bot_start_time: "11/1/2023 8:40:00",
    start_time: "11/1/2023 8:40:00",
    bot_end_time: "11/1/2023 8:40:00",
    end_time: "11/1/2023 8:40:00",
    execution_time: "0:00:00",
    process_name: "Caliber_Process",
    task_name: "Fetch_Broker_Ledger-Balance_View",
    application_name: "Caliber_Application",
    login_user_id: "rpa.consultant@360.one",
    status: "Started",
    description: "Bot started the execution",
    run_id: 1,
    process_id: 1112023024,
  },
  {
    id: 25,
    sr_no: 40941,
    bot_start_time: "11/1/2023 8:39:00",
    start_time: "11/1/2023 8:39:00",
    bot_end_time: "11/1/2023 8:53:00",
    end_time: "11/1/2023 8:53:00",
    execution_time: "0:14:00",
    process_name: "Caliber_Process",
    task_name: "Closing_Price_of_MF",
    application_name: "Caliber_Application",
    login_user_id: "rpa.consultant@360.one",
    status: "Successful",
    description: "Bot Completed the Execution",
    run_id: 3,
    process_id: 1112023024,
  },
  {
    id: 26,
    sr_no: 40942,
    bot_start_time: "11/1/2023 8:52:00",
    start_time: "11/1/2023 8:52:00",
    bot_end_time: "11/1/2023 8:52:00",
    end_time: "11/1/2023 8:52:00",
    execution_time: "0:00:00",
    process_name: "Caliber_Process",
    task_name: "Fetch_Broker_Stock_Balance_View-IIL_Broker",
    application_name: "Caliber_Application",
    login_user_id: "rpa.consultant@360.one",
    status: "Started",
    description: "Bot started the execution",
    run_id: 1,
    process_id: 1112023025,
  },
  {
    id: 27,
    sr_no: 40943,
    bot_start_time: "11/1/2023 9:24:00",
    start_time: "11/1/2023 9:24:00",
    bot_end_time: "11/1/2023 9:26:00",
    end_time: "11/1/2023 9:26:00",
    execution_time: "0:02:00",
    process_name: "Caliber_Process",
    task_name: "EOD_Caliber_Group1",
    application_name: "Caliber_Application",
    login_user_id: "rpa.consultant@360.one",
    status: "Successful",
    description: "Bot Completed The Execution",
    run_id: 1,
    process_id: 1112023026,
  },
  {
    id: 28,
    sr_no: 40944,
    bot_start_time: "11/1/2023 9:32:00",
    start_time: "11/1/2023 9:32:00",
    bot_end_time: "11/1/2023 9:40:00",
    end_time: "11/1/2023 9:40:00",
    execution_time: "0:08:00",
    process_name: "Caliber_Process",
    task_name: "Fetch_Broker_Stock_Balance_View-Wealth_Broker",
    application_name: "Caliber_Application",
    login_user_id: "rpa.consultant@360.one",
    status: "Successful",
    description: "Confirmation Process Successfully Executed",
    run_id: 1,
    process_id: 1112023027,
  },
  {
    id: 29,
    sr_no: 40945,
    bot_start_time: "11/1/2023 9:42:00",
    start_time: "11/1/2023 9:42:00",
    bot_end_time: "11/1/2023 9:50:00",
    end_time: "11/1/2023 9:50:00",
    execution_time: "0:08:00",
    process_name: "Caliber_Process",
    task_name: "BSE_Scheme_Master",
    application_name: "Caliber_Application",
    login_user_id: "rpa.consultant@360.one",
    status: "Successful",
    description: "BSE Scheme Master BOT Executed Successfully",
    run_id: 1,
    process_id: 1112023028,
  },
  {
    id: 30,
    sr_no: 40946,
    bot_start_time: "11/1/2023 9:46:00",
    start_time: "11/1/2023 9:46:00",
    bot_end_time: "11/1/2023 9:47:00",
    end_time: "11/1/2023 9:47:00",
    execution_time: "0:01:00",
    process_name: "Outlook_Process",
    task_name: "Daily_AUM_Process",
    application_name: "Outlook_Application",
    login_user_id: "rpa.consultant@360.one",
    status: "Successful",
    description: "Bot Execution finished",
    run_id: 1,
    process_id: 1112023029,
  },
  {
    id: 31,
    sr_no: 40947,
    bot_start_time: "11/1/2023 9:50:00",
    start_time: "11/1/2023 9:50:00",
    bot_end_time: "11/1/2023 9:52:00",
    end_time: "11/1/2023 9:52:00",
    execution_time: "0:02:00",
    process_name: "Outlook_Process",
    task_name: "Wealth_Payout_Process",
    application_name: "Outlook_Application",
    login_user_id: "rpa.consultant@360.one",
    status: "Successful",
    description: "Wealth_Payout_Process Execution Finished",
    run_id: 1,
    process_id: 1112023030,
  },
  {
    id: 32,
    sr_no: 40948,
    bot_start_time: "11/1/2023 9:53:00",
    start_time: "11/1/2023 9:53:00",
    bot_end_time: "11/1/2023 10:02:00",
    end_time: "11/1/2023 10:02:00",
    execution_time: "0:09:00",
    process_name: "IIFL-One_Process",
    task_name: "IIFLOne_BOD",
    application_name: "IIFL-One_Application",
    login_user_id: "corporate1",
    status: "Successful",
    description: "BOD Bot executed Successfully",
    run_id: 1,
    process_id: 1112023017,
  },
  {
    id: 33,
    sr_no: 40949,
    bot_start_time: "11/1/2023 9:54:00",
    start_time: "11/1/2023 9:54:00",
    bot_end_time: "11/1/2023 9:57:00",
    end_time: "11/1/2023 9:57:00",
    execution_time: "0:03:00",
    process_name: "IIFL-One_Process",
    task_name: "CASH_AND_EQ-BOD_Process",
    application_name: "IIFL-One_Application",
    login_user_id: "corporate1",
    status: "Successful",
    description: "Process Cash & EQ. submitted and completed Successfully",
    run_id: 1,
    process_id: 1112023017,
  },
  {
    id: 34,
    sr_no: 40950,
    bot_start_time: "11/1/2023 9:57:00",
    start_time: "11/1/2023 9:57:00",
    bot_end_time: "11/1/2023 9:59:00",
    end_time: "11/1/2023 9:59:00",
    execution_time: "0:02:00",
    process_name: "IIFL-One_Process",
    task_name: "Saleable_Holding-BOD_Process",
    application_name: "IIFL-One_Application",
    login_user_id: "corporate1",
    status: "Successful",
    description:
      "Process Saleable Holding submitted and completed Successfully",
    run_id: 1,
    process_id: 1112023017,
  },
  {
    id: 35,
    sr_no: 40951,
    bot_start_time: "11/1/2023 9:59:00",
    start_time: "11/1/2023 9:59:00",
    bot_end_time: "11/1/2023 10:02:00",
    end_time: "11/1/2023 10:02:00",
    execution_time: "0:03:00",
    process_name: "IIFL-One_Process",
    task_name: "Interest_Accrual_Process-BOD_Process",
    application_name: "IIFL-One_Application",
    login_user_id: "corporate1",
    status: "Successful",
    description:
      "Interest_Accrual_Process submitted and completed Successfully",
    run_id: 1,
    process_id: 1112023017,
  },
  {
    id: 36,
    sr_no: 40952,
    bot_start_time: "11/1/2023 10:04:00",
    start_time: "11/1/2023 10:04:00",
    bot_end_time: "11/1/2023 10:11:00",
    end_time: "11/1/2023 10:11:00",
    execution_time: "0:07:00",
    process_name: "ICRA_Analyst_Process",
    task_name: "PRC_ICRA_Analyst_Process",
    application_name: "ICRA_Application",
    login_user_id: "rpa.consultant@360.one",
    status: "Successful",
    description: "Bot Executed Successfully",
    run_id: 1,
    process_id: 1112023031,
  },
  {
    id: 37,
    sr_no: 40953,
    bot_start_time: "11/1/2023 10:16:00",
    start_time: "11/1/2023 10:16:00",
    bot_end_time: "11/1/2023 10:23:00",
    end_time: "11/1/2023 10:23:00",
    execution_time: "0:07:00",
    process_name: "Outlook_Process",
    task_name: "FrontPageEmail_Process",
    application_name: "Outlook_Application",
    login_user_id: "rpa.consultant@360.one",
    status: "Successful",
    description: "Bot Execution finished",
    run_id: 1,
    process_id: 1112023032,
  },
  {
    id: 38,
    sr_no: 40954,
    bot_start_time: "11/1/2023 10:45:00",
    start_time: "11/1/2023 10:45:00",
    bot_end_time: "11/1/2023 10:59:00",
    end_time: "11/1/2023 10:59:00",
    execution_time: "0:14:00",
    process_name: "Caliber_Process",
    task_name: "Closing_Price_of_MF",
    application_name: "Caliber_Application",
    login_user_id: "rpa.consultant@360.one",
    status: "Successful",
    description: "Bot Completed the Execution",
    run_id: 4,
    process_id: 1112023033,
  },
  {
    id: 39,
    sr_no: 40955,
    bot_start_time: "11/1/2023 11:02:00",
    start_time: "11/1/2023 11:02:00",
    bot_end_time: "11/1/2023 11:06:00",
    end_time: "11/1/2023 11:06:00",
    execution_time: "0:04:00",
    process_name: "IIFL-One_Process",
    task_name: "Company_Master",
    application_name: "IIFL-One_Application",
    login_user_id: "corporate1",
    status: "Successful",
    description: "Status is updated Successfully",
    run_id: 1,
    process_id: 1112023034,
  },
  {
    id: 40,
    sr_no: 40956,
    bot_start_time: "11/1/2023 11:07:00",
    start_time: "11/1/2023 11:07:00",
    bot_end_time: "11/1/2023 11:17:00",
    end_time: "11/1/2023 11:17:00",
    execution_time: "0:10:00",
    process_name: "Caliber_Process",
    task_name: "Debt_Price_Upload",
    application_name: "Caliber_Application",
    login_user_id: "rpa.consultant@360.one",
    status: "Failed",
    description: "Bot Failed While Logging Inside the Caliber Application",
    run_id: 1,
    process_id: 1112023035,
  },
  {
    id: 41,
    sr_no: 40957,
    bot_start_time: "11/1/2023 11:16:00",
    start_time: "11/1/2023 11:16:00",
    bot_end_time: "11/1/2023 11:16:00",
    end_time: "11/1/2023 11:16:00",
    execution_time: "0:00:00",
    process_name: "IIFL-One_Process",
    task_name: "Expense_Update",
    application_name: "IIFL-One_Application",
    login_user_id: "corporate1",
    status: "Failed",
    description: `Input File Download Failed."Equity Style-31/10/2023" Mail Not Found or Input File Not Found.`,
    run_id: 1,
    process_id: 1112023036,
  },
  {
    id: 42,
    sr_no: 40958,
    bot_start_time: "11/1/2023 11:30:00",
    start_time: "11/1/2023 11:30:00",
    bot_end_time: "11/1/2023 11:32:00",
    end_time: "11/1/2023 11:32:00",
    execution_time: "0:02:00",
    process_name: "Outlook_Process",
    task_name: "Wealth_Payout_Process",
    application_name: "Outlook_Application",
    login_user_id: "rpa.consultant@360.one",
    status: "Successful",
    description: "Wealth_Payout_Process Execution Finished",
    run_id: 2,
    process_id: 1112023037,
  },
  {
    id: 43,
    sr_no: 40959,
    bot_start_time: "11/1/2023 11:32:00",
    start_time: "11/1/2023 11:32:00",
    bot_end_time: "11/1/2023 11:40:00",
    end_time: "11/1/2023 11:40:00",
    execution_time: "0:08:00",
    process_name: "Caliber_Process",
    task_name: "MF_Upload",
    application_name: "Caliber_Application",
    login_user_id: "rpa.consultant@360.one",
    status: "Successful",
    description: "Bot Execute Sucessfully",
    run_id: 1,
    process_id: 1112023038,
  },
  {
    id: 44,
    sr_no: 40960,
    bot_start_time: "11/1/2023 11:41:00",
    start_time: "11/1/2023 11:41:00",
    bot_end_time: "11/1/2023 11:46:00",
    end_time: "11/1/2023 11:46:00",
    execution_time: "0:05:00",
    process_name: "IIFL-One_Process",
    task_name: "Holding",
    application_name: "IIFL-One_Application",
    login_user_id: "corporate1",
    status: "Successful",
    description: "Status is updated Successfully",
    run_id: 1,
    process_id: 1112023039,
  },
  {
    id: 45,
    sr_no: 40961,
    bot_start_time: "11/1/2023 11:49:00",
    start_time: "11/1/2023 11:49:00",
    bot_end_time: "11/1/2023 12:09:00",
    end_time: "11/1/2023 12:09:00",
    execution_time: "0:20:00",
    process_name: "IIFL-One_Process",
    task_name: "Bench_Mark_Price_Upload",
    application_name: "IIFL-One_Application",
    login_user_id: "corporate1",
    status: "Successful",
    description: "Bot Finished Execution",
    run_id: 1,
    process_id: 1112023040,
  },
  {
    id: 46,
    sr_no: 40962,
    bot_start_time: "11/1/2023 12:00:00",
    start_time: "11/1/2023 12:00:00",
    bot_end_time: "11/1/2023 12:13:00",
    end_time: "11/1/2023 12:13:00",
    execution_time: "0:13:00",
    process_name: "Caliber_Process",
    task_name: "BSE_Scheme_Master",
    application_name: "Caliber_Application",
    login_user_id: "rpa.consultant@360.one",
    status: "Successful",
    description: "BSE Scheme Master BOT Executed Successfully",
    run_id: 1,
    process_id: 1112023041,
  },
];

export const TASK_SCHEDULED_TABLE_DATA = [
  {
    id: 1,
    type: "Recurring",
    bot_name: "Main_RTA_WBR9",
    automation_priority: "Medium",
    schedule: "Every day at 14:00:00 IST",
    run_as_users: "test_runner",
    status: "Active",
    last_modified: "2023-08-17 15:49:14 IST",
  },
  {
    id: 2,
    type: "Recurring",
    bot_name: "Auto_NRI-Stock",
    automation_priority: "Medium",
    schedule: "Every week Mon, Tue, Wed, Thu, Fri at 16:00:00 IST",
    run_as_users: "test_runner",
    status: "Active",
    last_modified: "2023-07-18 17:22:25 IST",
  },
  {
    id: 3,
    type: "Recurring",
    bot_name: "Prod-QTS-Crisil_Reporting",
    automation_priority: "Medium",
    schedule: "Every week Tue, Wed, Thu, Fri, Sat at 17:30:00 IST",
    run_as_users: "runner1",
    status: "Active",
    last_modified: "2023-12-20 15:52:21 IST",
  },
  {
    id: 4,
    type: "Recurring",
    bot_name: "Prod-Caliber_Corporate_Action",
    automation_priority: "Medium",
    schedule: "Every week Mon, Tue, Wed, Thu, Fri at 18:00:00 IST",
    run_as_users: "test_runner",
    status: "Active",
    last_modified: "2023-09-13 16:53:13 IST",
  },
  {
    id: 5,
    type: "Recurring",
    bot_name: "Prod-Caliber_Securities_Indices",
    automation_priority: "Medium",
    schedule: "Every week Mon, Tue, Wed, Thu, Fri at 18:00:00 IST",
    run_as_users: "runner1",
    status: "Active",
    last_modified: "2023-07-17 18:06:30 IST",
  },
  {
    id: 6,
    type: "Recurring",
    bot_name: "Main_BSE_TRI_200",
    automation_priority: "Medium",
    schedule: "Every week Mon, Tue, Wed, Thu, Fri at 18:20:00 IST",
    run_as_users: "runner1",
    status: "Active",
    last_modified: "2023-07-17 16:51:02 IST",
  },
  {
    id: 7,
    type: "Recurring",
    bot_name: "Debt_price_160",
    automation_priority: "Medium",
    schedule: "Every week Mon, Tue, Wed, Thu, Fri at 18:30:00 IST",
    run_as_users: "test_runner",
    status: "Active",
    last_modified: "2023-07-17 16:37:05 IST",
  },
  {
    id: 8,
    type: "Recurring",
    bot_name: "NSE_BhavCopy",
    automation_priority: "Medium",
    schedule: "Every week Mon, Tue, Wed, Thu, Fri at 18:30:00 IST",
    run_as_users: "runner1",
    status: "Active",
    last_modified: "2023-07-17 17:00:25 IST",
  },
  {
    id: 9,
    type: "Recurring",
    bot_name: "NRI_Bank_Balance_Upload",
    automation_priority: "Medium",
    schedule: "Every week Mon, Tue, Wed, Thu, Fri, Sat at 18:30:00 IST",
    run_as_users: "test_runner",
    status: "Active",
    last_modified: "2023-11-01 13:54:13 IST",
  },
  {
    id: 10,
    type: "Recurring",
    bot_name: "MFRecon_CAMS-WBR53_InitiateDownload",
    automation_priority: "Medium",
    schedule: "Every week Mon, Tue, Wed, Thu, Sun at 18:40:00 IST",
    run_as_users: "test_runner",
    status: "Active",
    last_modified: "2023-09-13 16:52:33 IST",
  },
  {
    id: 11,
    type: "Recurring",
    bot_name: "Manual_File_Upload_21_Format",
    automation_priority: "Medium",
    schedule: "Every week Mon, Tue, Wed, Thu, Fri at 18:40:00 IST",
    run_as_users: "runner1",
    status: "Active",
    last_modified: "2023-07-17 16:53:39 IST",
  },
  {
    id: 12,
    type: "Recurring",
    bot_name: "Vidal_Price_Upload",
    automation_priority: "Medium",
    schedule: "Every week Mon, Tue, Wed, Thu, Fri at 19:00:00 IST",
    run_as_users: "runner1",
    status: "Active",
    last_modified: "2023-07-17 17:09:43 IST",
  },
];

export const DASHBOARD_FAILED_BOTS_TABLE = [
  {
    process_id: 1112023001,
    application_name: "Caliber_Application",
    task_name: "Closing_Price_of_MF",
    description: "Bot Completed the Execution",
  },
  {
    process_id: 1112023002,
    application_name: "Quantis_Application",
    task_name: "QTS-Equity_Price_Upload-BSE",
    description: "BSE File Uploaded Successfully in Quantis Application",
  },
  {
    process_id: 1112023003,
    application_name: "Quantis_Application",
    task_name: "QTS-Equity_Price_Upload-NSE",
    description: "NSE File Uploaded Successfully in Quantis Application",
  },
  {
    process_id: 1112023004,
    application_name: "Caliber_Application",
    task_name: "EOD_Caliber_Group2_Group3",
    description: "Bot Execution Finished Sucessfully",
  },
  {
    process_id: 1112023005,
    application_name: "Quantis_Application",
    task_name: "QTS-MF_Price",
    description: "File Uploaded Successfully on Quantis Application",
  },
  {
    process_id: 1112023006,
    application_name: "Quantis_Application",
    task_name: "QTS-Debt_Price_Upload",
    description: "Debt Price Upload file Successfully in Quantis Application",
  },
  {
    process_id: 1112023007,
    application_name: "Quantis_Application",
    task_name: "QTS-Expense_Ratio_Data_Upload",
    description: "File Uploaded Successfully on Website",
  },
  {
    process_id: 1112023008,
    application_name: "Quantis_Application",
    task_name: "QTS-Fund_Account_Holding-BackOffice",
    description: "File Uploaded Successfully on Quantis Application",
  },
  {
    process_id: 1112023009,
    application_name: "Quantis_Application",
    task_name: "QTS-NAV_Upload",
    description: "File Uploaded Successfully on Quantis Application",
  },
  {
    process_id: 1112023010,
    application_name: "IIFL-One_Application",
    task_name: "Holding_Reconciliation_AAA",
    description: "Bot Execute Successfully",
  },
];

export const STATUS_OPTIONS = [
  { value: "all", label: "All" },
  { value: "assigned", label: "Assigned" },
  { value: "RA", label: "Requirement Analysis" },
  { value: "SA", label: "Stakeholder Analysis" },
  { value: "demo", label: "Proposal & Demo" },
  { value: "lost", label: "Lost" },
  { value: "won", label: "Won" },
];

export const SOURCE_OPTIONS = [
  { value: "all", label: "All" },
  { value: "call", label: "Call" },
  { value: "email", label: "Email" },
  { value: "existing customer", label: "Existing Customer" },
  { value: "partner", label: "Partner" },
  { value: "public relations", label: "Public Relations" },
  { value: "campaign", label: "Campaign" },
  { value: "other", label: "Other" },
];

export const LEAD_OWNER_OPTIONS = [
  { value: "all", label: "All" },
  { value: "phone", label: "Phone" },
  { value: "email", label: "Email" },
  { value: "reference", label: "Reference" },
];

export const IS_ACTIVE_OPTIONS = [
  { value: "all", label: "All" },
  { value: "active", label: "Active" },
  { value: "inactive", label: "Inactive" },
];

export const STAGE_OPTIONS = [
  { label: "Lead Qualified", value: "lead_qualified" },
  { label: "Needs Analysis", value: "needs_analysis" },
  { label: "Proposal Made", value: "proposal_made" },
  { label: "Value Proposition", value: "value_proposition" },
  {
    label: "Decision Makers Brought In",
    value: "decision_makers",
  },
  {
    label: "Negotiation/Review",
    value: "negotiation_review",
  },
  { label: "Contract Sent", value: "contract_sent" },
  { label: "Closed Won", value: "closed_won" },
  { label: "Closed Lost", value: "closed_lost" },
];

export const USER_ROLE = [
  { value: "ADMIN", label: "ADMIN" },
  { value: "VIEWER", label: "VIEWER" },
  { value: "EDITOR", label: "EDITOR" },
];
export const INDUSTRY_TYPE = [
  { value: "manufacturing", label: "MANUFACTURING" },
  { value: "chemical", label: "CHEMICAL" },
  { value: "production", label: "PRODUCTION" },
];
export const ORGANIZATION_TYPE = [
  { value: "vendor", label: "VENDOR" },
  { value: "partner", label: "PARTNER" },
  { value: "broker", label: "BROKER" },
];
export const ACTIVITY_OPTION = [
  { value: "task", label: "Task" },
  { value: "meeting", label: "Meeting" },
];

export const TASK_ACTIVITY_STATUS = [
  { value: "Completed", label: "Completed" },
  { value: "Pending", label: "Pending" },
  { value: "InProg", label: "In Progress" },
];

export const TASK_ACTIVITY_PRIORITY = [
  { value: "High", label: "High" },
  { value: "Medium", label: "Medium" },
  { value: "Low", label: "Low" },
];

export const MEETING_ACTIVITY_TYPE = [
  { value: "Meeting", label: "Meeting" },
  { value: "Call", label: "Phone Call" },
  { value: "Email", label: "Email" },

];

export const DECISION_MAKER = [
  {
    value: true,
    label: "Yes",
  },
  {
    value: false,
    label: "No",
  },
];

export const COUNTRIES = [
  { value: "GB", label: "United Kingdom" },
  { value: "AF", label: "Afghanistan" },
  { value: "AX", label: "Aland Islands" },
  { value: "AL", label: "Albania" },
  { value: "DZ", label: "Algeria" },
  { value: "AS", label: "American Samoa" },
  { value: "AD", label: "Andorra" },
  { value: "AO", label: "Angola" },
  { value: "AI", label: "Anguilla" },
  { value: "AQ", label: "Antarctica" },
  { value: "AG", label: "Antigua and Barbuda" },
  { value: "AR", label: "Argentina" },
  { value: "AM", label: "Armenia" },
  { value: "AW", label: "Aruba" },
  { value: "AU", label: "Australia" },
  { value: "AT", label: "Austria" },
  { value: "AZ", label: "Azerbaijan" },
  { value: "BS", label: "Bahamas" },
  { value: "BH", label: "Bahrain" },
  { value: "BD", label: "Bangladesh" },
  { value: "BB", label: "Barbados" },
  { value: "BY", label: "Belarus" },
  { value: "BE", label: "Belgium" },
  { value: "BZ", label: "Belize" },
  { value: "BJ", label: "Benin" },
  { value: "BM", label: "Bermuda" },
  { value: "BT", label: "Bhutan" },
  { value: "BO", label: "Bolivia" },
  { value: "BA", label: "Bosnia and Herzegovina" },
  { value: "BW", label: "Botswana" },
  { value: "BV", label: "Bouvet Island" },
  { value: "BR", label: "Brazil" },
  { value: "IO", label: "British Indian Ocean Territory" },
  { value: "BN", label: "Brunei Darussalam" },
  { value: "BG", label: "Bulgaria" },
  { value: "BF", label: "Burkina Faso" },
  { value: "BI", label: "Burundi" },
  { value: "KH", label: "Cambodia" },
  { value: "CM", label: "Cameroon" },
  { value: "CA", label: "Canada" },
  { value: "CV", label: "Cape Verde" },
  { value: "KY", label: "Cayman Islands" },
  { value: "CF", label: "Central African Republic" },
  { value: "TD", label: "Chad" },
  { value: "CL", label: "Chile" },
  { value: "CN", label: "China" },
  { value: "CX", label: "Christmas Island" },
  { value: "CC", label: "Cocos (Keeling) Islands" },
  { value: "CO", label: "Colombia" },
  { value: "KM", label: "Comoros" },
  { value: "CG", label: "Congo" },
  { value: "CD", label: "Congo, The Democratic Republic of the" },
  { value: "CK", label: "Cook Islands" },
  { value: "CR", label: "Costa Rica" },
  { value: "CI", label: "Cote d'Ivoire" },
  { value: "HR", label: "Croatia" },
  { value: "CU", label: "Cuba" },
  { value: "CY", label: "Cyprus" },
  { value: "CZ", label: "Czech Republic" },
  { value: "DK", label: "Denmark" },
  { value: "DJ", label: "Djibouti" },
  { value: "DM", label: "Dominica" },
  { value: "DO", label: "Dominican Republic" },
  { value: "EC", label: "Ecuador" },
  { value: "EG", label: "Egypt" },
  { value: "SV", label: "El Salvador" },
  { value: "GQ", label: "Equatorial Guinea" },
  { value: "ER", label: "Eritrea" },
  { value: "EE", label: "Estonia" },
  { value: "ET", label: "Ethiopia" },
  { value: "FK", label: "Falkland Islands (Malvinas)" },
  { value: "FO", label: "Faroe Islands" },
  { value: "FJ", label: "Fiji" },
  { value: "FI", label: "Finland" },
  { value: "FR", label: "France" },
  { value: "GF", label: "French Guiana" },
  { value: "PF", label: "French Polynesia" },
  { value: "TF", label: "French Southern Territories" },
  { value: "GA", label: "Gabon" },
  { value: "GM", label: "Gambia" },
  { value: "GE", label: "Georgia" },
  { value: "DE", label: "Germany" },
  { value: "GH", label: "Ghana" },
  { value: "GI", label: "Gibraltar" },
  { value: "GR", label: "Greece" },
  { value: "GL", label: "Greenland" },
  { value: "GD", label: "Grenada" },
  { value: "GP", label: "Guadeloupe" },
  { value: "GU", label: "Guam" },
  { value: "GT", label: "Guatemala" },
  { value: "GG", label: "Guernsey" },
  { value: "GN", label: "Guinea" },
  { value: "GW", label: "Guinea-Bissau" },
  { value: "GY", label: "Guyana" },
  { value: "HT", label: "Haiti" },
  { value: "HM", label: "Heard Island and McDonald Islands" },
  { value: "VA", label: "Holy See (Vatican City State)" },
  { value: "HN", label: "Honduras" },
  { value: "HK", label: "Hong Kong" },
  { value: "HU", label: "Hungary" },
  { value: "IS", label: "Iceland" },
  { value: "IN", label: "India" },
  { value: "ID", label: "Indonesia" },
  { value: "IR", label: "Iran, Islamic Republic of" },
  { value: "IQ", label: "Iraq" },
  { value: "IE", label: "Ireland" },
  { value: "IM", label: "Isle of Man" },
  { value: "IL", label: "Israel" },
  { value: "IT", label: "Italy" },
  { value: "JM", label: "Jamaica" },
  { value: "JP", label: "Japan" },
  { value: "JE", label: "Jersey" },
  { value: "JO", label: "Jordan" },
  { value: "KZ", label: "Kazakhstan" },
  { value: "KE", label: "Kenya" },
  { value: "KI", label: "Kiribati" },
  { value: "KP", label: "Korea, Democratic People's Republic of" },
  { value: "KR", label: "Korea, Republic of" },
  { value: "KW", label: "Kuwait" },
  { value: "KG", label: "Kyrgyzstan" },
  { value: "LA", label: "Lao People's Democratic Republic" },
  { value: "LV", label: "Latvia" },
  { value: "LB", label: "Lebanon" },
  { value: "LS", label: "Lesotho" },
  { value: "LR", label: "Liberia" },
  { value: "LY", label: "Libyan Arab Jamahiriya" },
  { value: "LI", label: "Liechtenstein" },
  { value: "LT", label: "Lithuania" },
  { value: "LU", label: "Luxembourg" },
  { value: "MO", label: "Macao" },
  { value: "MK", label: "Macedonia, The Former Yugoslav Republic of" },
  { value: "MG", label: "Madagascar" },
  { value: "MW", label: "Malawi" },
  { value: "MY", label: "Malaysia" },
  { value: "MV", label: "Maldives" },
  { value: "ML", label: "Mali" },
  { value: "MT", label: "Malta" },
  { value: "MH", label: "Marshall Islands" },
  { value: "MQ", label: "Martinique" },
  { value: "MR", label: "Mauritania" },
  { value: "MU", label: "Mauritius" },
  { value: "YT", label: "Mayotte" },
  { value: "MX", label: "Mexico" },
  { value: "FM", label: "Micronesia, Federated States of" },
  { value: "MD", label: "Moldova" },
  { value: "MC", label: "Monaco" },
  { value: "MN", label: "Mongolia" },
  { value: "ME", label: "Montenegro" },
  { value: "MS", label: "Montserrat" },
  { value: "MA", label: "Morocco" },
  { value: "MZ", label: "Mozambique" },
  { value: "MM", label: "Myanmar" },
  { value: "NA", label: "Namibia" },
  { value: "NR", label: "Nauru" },
  { value: "NP", label: "Nepal" },
  { value: "NL", label: "Netherlands" },
  { value: "AN", label: "Netherlands Antilles" },
  { value: "NC", label: "New Caledonia" },
  { value: "NZ", label: "New Zealand" },
  { value: "NI", label: "Nicaragua" },
  { value: "NE", label: "Niger" },
  { value: "NG", label: "Nigeria" },
  { value: "NU", label: "Niue" },
  { value: "NF", label: "Norfolk Island" },
  { value: "MP", label: "Northern Mariana Islands" },
  { value: "NO", label: "Norway" },
  { value: "OM", label: "Oman" },
  { value: "PK", label: "Pakistan" },
  { value: "PW", label: "Palau" },
  { value: "PS", label: "Palestinian Territory, Occupied" },
  { value: "PA", label: "Panama" },
  { value: "PG", label: "Papua New Guinea" },
  { value: "PY", label: "Paraguay" },
  { value: "PE", label: "Peru" },
  { value: "PH", label: "Philippines" },
  { value: "PN", label: "Pitcairn" },
  { value: "PL", label: "Poland" },
  { value: "PT", label: "Portugal" },
  { value: "PR", label: "Puerto Rico" },
  { value: "QA", label: "Qatar" },
  { value: "RE", label: "Reunion" },
  { value: "RO", label: "Romania" },
  { value: "RU", label: "Russian Federation" },
  { value: "RW", label: "Rwanda" },
  { value: "BL", label: "Saint Barthelemy" },
  { value: "SH", label: "Saint Helena" },
  { value: "KN", label: "Saint Kitts and Nevis" },
  { value: "LC", label: "Saint Lucia" },
  { value: "MF", label: "Saint Martin" },
  { value: "PM", label: "Saint Pierre and Miquelon" },
  { value: "VC", label: "Saint Vincent and the Grenadines" },
  { value: "WS", label: "Samoa" },
  { value: "SM", label: "San Marino" },
  { value: "ST", label: "Sao Tome and Principe" },
  { value: "SA", label: "Saudi Arabia" },
  { value: "SN", label: "Senegal" },
  { value: "RS", label: "Serbia" },
  { value: "SC", label: "Seychelles" },
  { value: "SL", label: "Sierra Leone" },
  { value: "SG", label: "Singapore" },
  { value: "SK", label: "Slovakia" },
  { value: "SI", label: "Slovenia" },
  { value: "SB", label: "Solomon Islands" },
  { value: "SO", label: "Somalia" },
  { value: "ZA", label: "South Africa" },
  { value: "GS", label: "South Georgia and the South Sandwich Islands" },
  { value: "ES", label: "Spain" },
  { value: "LK", label: "Sri Lanka" },
  { value: "SD", label: "Sudan" },
  { value: "SR", label: "Suriname" },
  { value: "SJ", label: "Svalbard and Jan Mayen" },
  { value: "SZ", label: "Swaziland" },
  { value: "SE", label: "Sweden" },
  { value: "CH", label: "Switzerland" },
  { value: "SY", label: "Syrian Arab Republic" },
  { value: "TW", label: "Taiwan, Province of China" },
  { value: "TJ", label: "Tajikistan" },
  { value: "TZ", label: "Tanzania, United Republic of" },
  { value: "TH", label: "Thailand" },
  { value: "TL", label: "Timor-Leste" },
  { value: "TG", label: "Togo" },
  { value: "TK", label: "Tokelau" },
  { value: "TO", label: "Tonga" },
  { value: "TT", label: "Trinidad and Tobago" },
  { value: "TN", label: "Tunisia" },
  { value: "TR", label: "Turkey" },
  { value: "TM", label: "Turkmenistan" },
  { value: "TC", label: "Turks and Caicos Islands" },
  { value: "TV", label: "Tuvalu" },
  { value: "UG", label: "Uganda" },
  { value: "UA", label: "Ukraine" },
  { value: "AE", label: "United Arab Emirates" },
  { value: "US", label: "United States" },
  { value: "UM", label: "United States Minor Outlying Islands" },
  { value: "UY", label: "Uruguay" },
  { value: "UZ", label: "Uzbekistan" },
  { value: "VU", label: "Vanuatu" },
  { value: "VE", label: "Venezuela" },
  { value: "VN", label: "Viet Nam" },
  { value: "VG", label: "Virgin Islands, British" },
  { value: "VI", label: "Virgin Islands, U.S." },
  { value: "WF", label: "Wallis and Futuna" },
  { value: "EH", label: "Western Sahara" },
  { value: "YE", label: "Yemen" },
  { value: "ZM", label: "Zambia" },
  { value: "ZW", label: "Zimbabwe" },
];
