import React, { useEffect, useState } from "react";
import TitleHeader from "src/components/common/title-header";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  Tooltip,
  TablePagination,
} from "@mui/material";
import { Edit, DeleteForever, Visibility } from "@mui/icons-material";
import TableHeader from "./components/table-header";
import styles from "./style.module.css";
import LeadModal from "./components/lead-modal";
import {
  useDeleteLeadsMutation,
  useLeadsData,
  useLeadsMutation,
  usePatchLeadsMutation,
} from "src/hooks/useLeadsData";
import Loader from "src/components/common/loader";
import { formatDate } from "src/utils/formatTime";
import DeleteLeadsModal from "./components/delete-modal";
import classNames from "classnames";
import { showNotification } from "src/components/notification";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { useQueryClient } from "@tanstack/react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import NameProfilePic from "src/components/common/name-profile-pic";
import EmptyTableMessage from "src/components/common/EmptyTableMessage";

const LeadsPage = () => {
  const rowsPerPage = 10;
  const initialPage = 0;
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [query, setQuery] = useState({ limit: rowsPerPage, page: 1 });
  const [deleteModal, setDeleteModal] = useState(false);
  const [edit, setEdit] = useState(false);
  const [deleteUserData, setDeleteUserData] = useState({});
  const [searchField, setSearchField] = useState("");

  const [formData, setFormData] = useState({});
  const [selectedRow, setSelectedRow] = useState({});
  const [filterData, setFilterData] = useState({
    source: "all",
    status: "all",
    lead_owner: "all",
    is_active: "all",
  });
  const leadsMutation = useLeadsMutation();
  const deleteLeadsMutation = useDeleteLeadsMutation();
  const patchLeadsMutation = usePatchLeadsMutation();
  const [openFilter, setOpenFilter] = useState(false);
  const [page, setPage] = React.useState(initialPage);
  const [selectedItems, setSelectedItems] = useState([]);
  const { data, isLoading } = useLeadsData(query);
  // React router utility hook to get the query params
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const searchQuery = searchParams.get("search");
    const statusQuery = searchParams.get("status");
    const sourceQuery = searchParams.get("source");
    const leadOwnerQuery = searchParams.get("lead_owner");
    const isActiveQuery = searchParams.get("is_active");

    setFilterData({
      ...filterData,
      search: searchQuery || "all",
      status: statusQuery || "all",
      source: sourceQuery || "all",
      lead_owner: leadOwnerQuery || "all",
      is_active: isActiveQuery || "all",
    });

    setQuery({
      ...query,
      search: searchQuery,
      status: statusQuery,
      source: sourceQuery,
      lead_owner: leadOwnerQuery,
      is_active: isActiveQuery,
    });
  }, [searchParams]); // eslint-disable-line

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      const params = new URLSearchParams();

      Object.entries(query).forEach(([key, value]) => {
        if (value) params.append(key, value);
      });
      navigate(`?${params}`, { replace: true }, { shallow: true });
      queryClient.invalidateQueries({ queryKey: ["leadsData"] });
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [query, page]); //eslint-disable-line

  const handleSearchChange = (event) => {
    setQuery({
      ...query,
      [event.target.name]: event.target.value,
    });
  };

  const totalPages = Math.ceil((data?.count || 0) / rowsPerPage);

  const handleChangePage = (event, newPage) => {
    const offset = newPage * rowsPerPage;
    setPage(newPage);
    setQuery({ ...query, page: newPage + 1, offset });
  };

  const handleOpenFilter = () => {
    setOpenFilter(true);
    setOpen(false);
  };

  const handleReset = () => {
    setSelectedRow({ source: "default" });
    setFormData({
      source: "default",
    });
  };

  const handleFilterReset = (e) => {
    setFilterData({
      source: "all",
      status: "all",
      lead_owner: "all",
      is_active: "all",
    });
    setOpenFilter(false);
    setQuery("");
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFilterDataChange = (event) => {
    const { name, value } = event.target;
    setFilterData({
      ...filterData,
      [name]: value,
    });
    setQuery({
      ...query,
      [name]: value === "all" ? "" : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (edit) {
      try {
        await patchLeadsMutation.mutateAsync(
          { body: formData, id: selectedRow.id },
          {
            onSuccess: () => {
              queryClient.invalidateQueries({ queryKey: ["leadsData"] });
              showNotification("success", "Lead Updated Successfully.!", 2000);
              handleReset();
              setEdit(false);
              setOpen(false);
            },
          }
        );
      } catch (error) {
        showNotification("error", error, 8000);
      }
    } else {
      try {
        await leadsMutation.mutateAsync(formData, {
          onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["leadsData"] });
            showNotification("success", "Leads Added Successfully.!", 2000);
            handleReset();
            setOpen(false);
          },
        });
      } catch (error) {
        showNotification("error", error, 8000);
      }
    }
  };

  const handleDelete = async (e) => {
    e.preventDefault();
    try {
      await deleteLeadsMutation.mutateAsync(deleteUserData?.id, {
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: ["leadsData"] });
          showNotification("success", "Leads Deleted Successfully.!", 2000);
          handleReset();
          setOpen(false);
        },
      });
    } catch (error) {
      showNotification("error", error, 8000);
    }
  };

  const handleSelectItem = (id) => {
    if (selectedItems.includes(id)) {
      setSelectedItems(selectedItems.filter((itemId) => itemId !== id));
    } else {
      setSelectedItems([...selectedItems, id]);
    }
  };

  const handleDeleteSelected = () => {
    setSelectedItems([]);
  };

  const filteredLeads = data?.results || [];

  return isLoading ? (
    <Loader />
  ) : (
    <Box>
      <ToastContainer />
      <TitleHeader
        title={"LEADS"}
        sx={{
          color: "#ff0000",
          fontWeight: "bold",
        }}
      />
      <Box className={styles["table_box"]}>
        <TableHeader
          query={query}
          handleChange={handleSearchChange}
          styles={styles}
          handleOpen={() => setOpen(true)}
          openFilter={openFilter}
          handleOpenFilter={handleOpenFilter}
          handleCloseFilter={() => setOpenFilter(false)}
          handleSearch={handleSearchChange}
          searchField={searchField}
          setSearchField={setSearchField}
          filterData={filterData}
          handleFilterDataChange={handleFilterDataChange}
          handleFilterReset={handleFilterReset}
          selectedCount={selectedItems.length}
          handleDeleteSelected={handleDeleteSelected}
        />
        <TableContainer component={Paper}>
          <Table>
            <TableHead className={styles["tHead"]}>
              <TableRow>
                <TableCell className={styles["table_cell"]}>
                  <Checkbox
                    className={styles["checkBox"]}
                    checked={selectedItems.length === filteredLeads.length}
                    indeterminate={
                      selectedItems.length > 0 &&
                      selectedItems.length < filteredLeads.length
                    }
                    onChange={() => {
                      if (selectedItems.length === filteredLeads.length) {
                        setSelectedItems([]);
                      } else {
                        const allIds = filteredLeads.map((item) => item.id);
                        setSelectedItems(allIds);
                      }
                    }}
                  />
                </TableCell>
                <TableCell className={styles["table_cell"]}>Name</TableCell>
                <TableCell className={styles["table_cell"]}>Company</TableCell>
                <TableCell className={styles["table_cell"]}>Source</TableCell>
                <TableCell className={styles["table_cell"]}>Phone</TableCell>
                <TableCell className={styles["table_cell"]}>Status</TableCell>
                <TableCell className={styles["table_cell"]}>Assignee</TableCell>
                <TableCell className={styles["table_cell"]}>
                  Create Date
                </TableCell>
                <TableCell
                  className={classNames(
                    styles["table_cell"],
                    styles["table_action_header"]
                  )}
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredLeads.length === 0 ? (
                <EmptyTableMessage colSpan={10} message="No results found." />
              ) : (
                filteredLeads.map((row) => (
                  <TableRow className={styles["table-body-row"]} key={row?.id}>
                    <TableCell className={styles["table_cell"]}>
                      <Checkbox
                        className={styles["checkBox"]}
                        checked={selectedItems.includes(row?.id)}
                        onChange={() => handleSelectItem(row?.id)}
                      />
                    </TableCell>

                    <TableCell className={styles["table_cell"]}>
                      <Box className={styles["avatar_box"]}>
                        <Box className={styles["avatar_inner_box"]}>
                          <NameProfilePic
                            letter={row?.first_name?.charAt(0).toUpperCase()}
                          />
                        </Box>
                        <Box className="flex-grow-1 ms-2 name">
                          {`${row?.first_name} ${row?.last_name}`}
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell className={styles["table_cell"]}>
                      {row?.company || "----"}
                    </TableCell>
                    <TableCell
                      className={classNames(
                        styles?.source,
                        styles["table_cell"]
                      )}
                    >
                      {row?.source}
                    </TableCell>
                    <TableCell className={styles["table_cell"]}>
                      {row?.phone}
                    </TableCell>
                    <TableCell className={styles["table_cell"]}>
                      {row?.status || "----"}
                    </TableCell>
                    <TableCell
                      className={styles["table_cell"]}
                      sx={{ textTransform: "capitalize" }}
                    >
                      {row?.assignee?.user?.fullname || "----"}
                    </TableCell>
                    {/* <TableCell>
                      <Box
                        className={classNames(
                          styles["info-chip"],
                          row?.is_active ? "" : styles["danger-chip"]
                        )}
                      >
                        {row?.is_active ? "Active" : "Inactive"}
                      </Box>
                    </TableCell> */}
                    <TableCell className="date">
                      {formatDate(row?.created_at)}
                    </TableCell>
                    <TableCell className={styles["row_action"]}>
                      <ul
                        style={{
                          width: "fit-content",
                          listStyle: "none",
                          padding: 0,
                          display: "flex",
                        }}
                      >
                        <li
                          className="list-inline-item"
                          onClick={() =>
                            navigate(
                              `/dashboard/leads/${row?.id}?path=lead-summary`
                            )
                          }
                        >
                          <Tooltip title="View" placement="top">
                            <Visibility
                              sx={{
                                color: "#878a99",
                                marginRight: "0.6rem",
                                fontSize: "17px",
                              }}
                            />
                          </Tooltip>
                        </li>
                        <li
                          className="list-inline-item"
                          onClick={() => {
                            setSelectedRow({ ...selectedRow, ...row });
                            setEdit(true);
                            setOpen(true);
                          }}
                        >
                          <Tooltip title="Edit" placement="top">
                            <Edit
                              sx={{
                                color: "#878a99",
                                marginRight: "0.6rem",
                                fontSize: "17px",
                              }}
                            />
                          </Tooltip>
                        </li>
                        <li
                          className="list-inline-item"
                          onClick={() => {
                            setDeleteModal(true);
                            setDeleteUserData({
                              name: `${row?.first_name} ${row?.last_name}`,
                              id: row?.id,
                            });
                          }}
                        >
                          <Tooltip title="Delete" placement="top">
                            <DeleteForever
                              sx={{
                                color: "#878a99",
                                marginRight: "0.6rem",
                                fontSize: "17px",
                              }}
                            />
                          </Tooltip>
                        </li>
                      </ul>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[rowsPerPage]}
          component="div"
          count={data?.count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          totalPages={totalPages}
        />
      </Box>
      <DeleteLeadsModal
        open={deleteModal}
        handleClose={() => setDeleteModal(false)}
        deleteUserData={deleteUserData}
        setDeleteUserData={setDeleteUserData}
        handleDelete={handleDelete}
        styles={styles}
        selectedRow={selectedRow}
      />
      <LeadModal
        open={open}
        styles={styles}
        handleReset={handleReset}
        handleOpen={() => setOpen(true)}
        handleClose={() => setOpen(false)}
        handleSubmit={handleSubmit}
        formData={formData}
        handleChange={handleChange}
        edit={edit}
        setEdit={setEdit}
        selectedRow={selectedRow}
      />
    </Box>
  );
};

export default LeadsPage;
